import 'react-image-lightbox/style.css';

import React, { Component } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Lightbox from 'react-image-lightbox';

import { callBackendAPI, handleFormValidation, handleFormInputs, callBackendAPIAndImageUpload , reFormatSuccess, reFormatErrors, customConfirmBox, customFixedValue} from './../../helpers/common';
import {STATUS_SUCCESS, DATERANGE_DATE_TIME_FORMAT, ACTIVE, ELEC_DEPARTMENT, OFFICE_DEPARTMENT, OSP_DEPARTMENT, CTR_DEPARTMENT, HDD_DEPARTMENT, ISP_DEPARTMENT, FIBER_DEPARTMENT, SHIFT_DAY, CURRENCY_SYMBOL, CALENDAR_PTO, CALENDAR_YARD, CALENDAR_SUB_CONTRACTOR, CALENDAR_DATE_TIME_FORMAT, ADMIN, PROJECT_MANAGER, DISPATCHER, FOREMAN, ALLOWED_IMAGE_EXTENSIONS }  from './../../config/global_constants';
import { translate, formatDate  } from './../../helpers/intl_helpers';

import NoteBody from './cal/NoteBody';
import { UserDetails }  from '../../UserDetails';

import downloadIcon from '../../assets/images/download_icon.svg';
import nightIcon from '../../assets/images/moon_stars_icon.svg';
import dayIcon from '../../assets/images/sun_icon.svg';
import editIcon from '../../assets/images/edit_mode_icon.svg';
import copyIcon from '../../assets/images/copy_icon.svg';
import deleteIcon from '../../assets/images/delete-icon-red.svg';
import addPlusIcon from '../../assets/images/add_plus_icon.svg';

import GoogleMap from './../../elements/GoogleMap';

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
		{translate("calendar.file_extension_validation")}
    </Tooltip>
);

export default class EventItemModal extends Component {

	static contextType = UserDetails;
	constructor(props){
		super(props);

        this.state = {
			isLoading	: 	true,
			jobDetails	: 	true,
			fileUrl		:	"",
			jobList     : 	[],
			fields		:	{},
			submitted	:   false,
			errors      :   {},
			isShowAttachmentForm: false,
			lightBoxShow	: false,
			lightBoxImgUrl	: "",
			isForemanDisable: this.props.isForemanDisable
        };

		this.mapElementRef 		= React.createRef();
		this.fetchEventDetails 	= this.fetchEventDetails.bind(this);
		this.showMultipleValue 	= this.showMultipleValue.bind(this);
    }

	/** For call event details function */
	componentDidMount() {
        if(this.props.eventId) {
            this.fetchEventDetails(this.props.eventId);
        }
    }

	componentDidUpdate(prevProps) {
        if (this.props.eventId !== prevProps.eventId) {
            this.fetchEventDetails(this.props.eventId);
        }
    }

	/** Function to handle image lightbox */
	showImageLightbox = (imageUrl)=>{
		this.setState({
			lightBoxShow	:	true,
			lightBoxImgUrl	:	imageUrl
		});
	}

	/** For download image */
	handleDownload = () => {
		const {lightBoxImgUrl} = this.state;
		const link = document.createElement('a');
		link.href = lightBoxImgUrl;
		link.target = "_new";
		link.download = lightBoxImgUrl;
		link.click(); // Trigger the download
	};

	/** Get Event details */
	fetchEventDetails(eventId){
		/** Update loading stats */
		this.setState({ isLoading : true},()=>{

			/** Call event details API  */
			callBackendAPI([{ model: 'calendar', method: 'calendarEventDetails', job_id: eventId}]).then(response =>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					let allFields = this.state.fields;
					allFields['notes'] = {value: response.data[0].result.notes}

					let jobData = response.data[0].result;
					let startDate = jobData.start_date;
					let endDate = jobData.end_date;
					let isYardOrPTO 	=	(jobData && jobData.foreman_id && (jobData.foreman_id === CALENDAR_PTO || jobData.foreman_id === CALENDAR_YARD)) ? true :false;
					/** Update stats */
					this.setState({
						isLoading	: false,
						jobDetails	: response.data[0].result,
						fileUrl		: response.data[0].fileUrl,
						fields		: allFields
					},()=>{
						if(!isYardOrPTO) this.getJobList(startDate,endDate);
						window.hideLoader();
					});
				}else{
					this.props.closeJobDetailModal();
				}
			}).catch(err => console.log(err));
		});
	}//fetchEventDetails

	showMultipleValue(objectList, value){
		let str = [];
		if(objectList && objectList.constructor === Array && Object.keys(objectList).length && value){
			value = value.constructor === String  ? [value] : value;

			Object.keys(objectList).map(key=>{
				if(objectList[key].value && value.indexOf(objectList[key].value) !== -1){
					str.push(objectList[key].label);
				}
				return null;
			});
		}
		return str.join(", ");
	}


	/**
	* For update state fileds values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	/**update attachment details on submit */
	updateAttachmentDetails = (e,jobId) =>{
		const { image, notes } = this.state.fields;

		this.setState({
            submitted    :   true
        },()=>{
			/** Api Requested Data **/
            let params = [{
				model	: 	'jobs',
                method	: 	'updateAttachment',
                job_id	: 	jobId,
				notes	: 	(notes && notes.value)    ?	notes.value	  :"",
			}];

			let validations = {
				// notes   : { "label": translate('job.notes'), "rules" :["required"] }
			};

			/** Handle validation and api response **/
            handleFormValidation(validations,this.state.fields, params, false,true,true).then(async(response)=>{
				this.setState({ submitted: false});
                if(!response.success){
                    return this.setState({
                        errors          :   response.errors,
                    });
                }

				let filesArray = [];
				if (image && image.value) {
					if (image.value instanceof FileList) {
						filesArray = Array.from(image.value);
					} else if (image.value instanceof File) {
						filesArray = [image.value];
					}
				}

				// Create FormData and append files
				const formData = new FormData();
				filesArray.forEach(file => {
					formData.append("attachment", file);
				});

				callBackendAPIAndImageUpload({ model: 'jobs', method: 'updateAttachment', fileData: formData, formData: params[0]},true).then(response => {
					if(response.success && response.data.status === STATUS_SUCCESS) {
						/** Show success flash message **/
						reFormatSuccess(response.data.message);
						this.fetchEventDetails(jobId);

						return this.setState({errors : {}, fields: {}});
					}else{
						return this.setState({
							errors : reFormatErrors(response.data.message),
						});
					}
				}).catch(err => console.log(err));
			}).catch(err=> console.log(err));
		});
	}// end updateAttachmentDetails()

	/**
	* For get job listing
	*/
	getJobList = (startDate,endDate) => {
		window.showLoader();

		/** Set loading stats */
		this.setState({isLoading:true},()=>{
			/** Set api params */
			let apiReq 	=	{ model: 'dashboard', method: 'getJobsByZip',from_date: startDate, to_date: endDate, job_id: this.props.eventId};

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				window.hideLoader();
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						jobList  :	response.data[0].result,
						isLoading: 	false
					},()=>{
						this.mapElementRef.current.markerInitiated();
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getJobList()

	/**
	* For delete attachment
	*/
	deleteAttachment = (e,job_id,attachment_id) =>{
		e.preventDefault();
		let confirmation = customConfirmBox(translate("calendar.you_want_to_delete_this_attachment"));
		confirmation.then((confirmed) => {
            if (confirmed) {
				callBackendAPI([{ model: 'jobs', method: 'deleteAttachment',job_id: job_id, attachment_id: attachment_id}]).then(response=>{
					if(response.success && response.data[0].status === STATUS_SUCCESS){
						/** Show success flash message **/
						reFormatSuccess(response.data[0].message);
						this.fetchEventDetails(job_id);
					}
				}).catch(err => console.log(err));
			}
		});
	}// end deleteAttachment()

	render() {
		const {isLoading,jobDetails,fileUrl, fields, submitted, isForemanDisable, jobList, lightBoxShow, lightBoxImgUrl} = this.state;
		const { userDetails } = this.context;

		let isYardOrPTO 	=	(jobDetails && jobDetails.foreman_id && (jobDetails.foreman_id === CALENDAR_PTO || jobDetails.foreman_id === CALENDAR_YARD)) ? true :false;
		let isYard 			=	(jobDetails && jobDetails.foreman_id && jobDetails.foreman_id === CALENDAR_YARD) ? true :false;
		let isSubContractor =	(jobDetails && jobDetails.foreman_id && jobDetails.foreman_id === CALENDAR_SUB_CONTRACTOR) ? true :false;
		let currentDate      = new Date();
		currentDate.setDate(currentDate.getDate() - 14);
		currentDate=formatDate(currentDate, CALENDAR_DATE_TIME_FORMAT);
		let permissionLevel = 	(userDetails && userDetails.permission_level) ? userDetails.permission_level : "";
		let isReadOnly 		= 	(permissionLevel && (permissionLevel === PROJECT_MANAGER || permissionLevel === ADMIN)) ? false : true;
		let eventDate 		= 	formatDate(jobDetails.date, CALENDAR_DATE_TIME_FORMAT);

		return <>
			{!isLoading ?
				<>

					<div className="modal fade view-modal job-details-modal show d-block" id="eventDetailsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
					aria-labelledby="eventDetailsModal" aria-hidden="true">
						<div className="modal-dialog modal-xl">
							<div className="modal-content">
								<button type="button" className="btn-close" onClick={()=> {this.props.closeJobDetailModal()} }>X Close</button>

								<div className="modal-body">
									<h3>
										{isYardOrPTO ? (isYard ? (jobDetails.subject ? jobDetails.subject : translate('calendar.yard_details')) : translate('calendar.time_off_details')) : (isSubContractor ? translate('calendar.sub_contractor_job_details') : translate('calendar.job_details'))}
										{!isYardOrPTO ?
											<>
												&nbsp;
												(
													{jobDetails.job_number ? jobDetails.job_number :""}
														&nbsp; {!jobDetails.job_number ? translate("calendar.pending") : "-"} &nbsp;
													{jobDetails.work_order_number ? jobDetails.work_order_number :""}
												)
												{jobDetails.shift_type === SHIFT_DAY ?
													<div className="shifts-icon">
														<figure>
															<img src={dayIcon} alt="img" />
														</figure>
													</div>
												:
													<div className="shifts-icon">
														<figure>
															<img src={nightIcon} alt="img" />
														</figure>
													</div>
												}
											</>
										:null }
										<div className="detail-job-btns d-flex align-items-center">
											{(eventDate >= currentDate && (!isReadOnly) && !isForemanDisable) ?
												<>
												<button type="button" className="job-detail-edit" onClick={()=> {this.props.openEditModal(jobDetails.foreman_id, jobDetails._id)} }>
													<img src={editIcon} alt="img" />
												</button>
												</>
											:null}

											{(eventDate >= currentDate && !this.props.isMobileScreen && (!isReadOnly) && !isForemanDisable) ?
												<>
													<button type="button" className="job-detail-edit" onClick={()=> {this.props.openEditModal(jobDetails.foreman_id, jobDetails._id,true)} }>
														<img src={copyIcon} alt="img" />
													</button>
												</>
											:null}
										</div>
										{(jobDetails.created_name || jobDetails.modified_name) ?
											<p className="last-update-user">{translate('calendar.last_updated_by')} : {jobDetails.created_name ? jobDetails.created_name : (jobDetails.modified_name ? jobDetails.modified_name : "")}</p>
										: null}
									</h3>
									<p className="job-address-text mb-0">{jobDetails.jobsite_address ? jobDetails.jobsite_address : ""}</p>
									{(jobDetails.project_name || jobDetails.project_manager_name) ?
										<p className="job-address-text mt-0">
											<small>
											{jobDetails.project_name && jobDetails.project_manager_name
												? `${jobDetails.project_name} - ${jobDetails.project_manager_name}`
												: jobDetails.project_name || jobDetails.project_manager_name || translate('calendar.no_detail')}
											</small>
										</p>
									: null}
									{isYardOrPTO ?
										<div className="list-group list-group-flush">
											<div className="row">
												<div className="col-lg-3">
													<div className="list-group-item flex-row">
														<p>{translate('calendar.date')}</p>
														<div>{jobDetails.date ? formatDate(jobDetails.date, DATERANGE_DATE_TIME_FORMAT) :""}</div>
													</div>
												</div>
											</div>
											{isYard ?
												<div className="row">
													<div className="col-lg-12">
														<div className="list-group-item full-view">
															<p>{translate('calendar.notes')}</p>
															<div className='nl2br'>{jobDetails.notes ? jobDetails.notes :"N/A"}</div>
														</div>
													</div>
												</div>
											:null}
											{isYard ?
												<div className="row">
													{jobDetails.crewMembers && jobDetails.crewMembers.length ?
														<div className={isYard && jobDetails.assets && jobDetails.assets.length ? "col-lg-6" :"col-lg-12" }>
															<div className="list-group-item full-view">
																<p>{translate('calendar.employees')}</p>
																<div>
																	<ul>
																		{jobDetails.crewMembers.map((element, index) =>
																			<li className="assets-list-wrap" key={'job-details-crew-'+index} style={{backgroundColor: element.bgColor}}>
																				<img src={element.image} alt="crew-img"/>
																				<span dangerouslySetInnerHTML={{__html: element.groupName ? element.groupName :""}}></span>
																			</li>
																		)}
																	</ul>
																</div>
															</div>
														</div>
													:null}
													{jobDetails.assets && jobDetails.assets.length ?
														<div className="col-lg-6">
															<div className="list-group-item full-view">
																<p>{translate('calendar.equipment')}</p>
																<div>
																	<ul>
																		{jobDetails.assets.map((element, index) =>
																			<li className="assets-list-wrap" key={'job-details-asset-'+index} style={{backgroundColor: element.bgColor}}>
																				<img src={element.image} alt="crew-img"/>
																				<span dangerouslySetInnerHTML={{__html: element.groupName ? element.groupName :""}}></span>
																			</li>
																		)}
																	</ul>
																</div>
															</div>
														</div>
													:null}
												</div>
											:
												<div className="row">
													{jobDetails.paid_employee_list && jobDetails.paid_employee_list.length ?
														<div className={jobDetails.unpaid_employee_list.length ? "col-lg-6" :"col-lg-12" }>
															<div className="list-group-item full-view">
																<p>{translate('calendar.paid_employees')}</p>
																<div>
																	<ul>
																		{jobDetails.paid_employee_list.map((element, index) =>
																			<li className="assets-list-wrap" key={'job-details-crew-'+index} style={{backgroundColor: element.bgColor}}>
																				<img src={element.image} alt="crew-img"/>
																				<span>{element.groupName}</span>
																			</li>
																		)}
																	</ul>
																</div>
															</div>
														</div>
													:null}
													{jobDetails.unpaid_employee_list && jobDetails.unpaid_employee_list.length ?
														<div className="col-lg-6">
															<div className="list-group-item full-view">
																<p>{translate('calendar.unpaid_employees')}</p>
																<div>
																	<ul>
																		{jobDetails.unpaid_employee_list.map((element, index) =>
																			<li className="assets-list-wrap" key={'job-details-crew-'+index} style={{backgroundColor: element.bgColor}}>
																				<img src={element.image} alt="crew-img"/>
																				<span>{element.groupName}</span>
																			</li>
																		)}
																	</ul>
																</div>
															</div>
														</div>
													:null}
												</div>
											}
										</div>
									:

										<div className="accordion" id="jobDetailAccordion">

											<div className="accordion-item">
												<h2 className="accordion-header">
												<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseTwo" aria-expanded="true" aria-controls="jobDetailsCollapseTwo">
													{translate('calendar.job_details')}
												</button>
												</h2>
												<div id="jobDetailsCollapseTwo" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-0">
													<div className="accordion-body">
														<div className="list-group list-group-flush">
															<div className="row">
																<div className="col-lg-6">
																	<div className="row">
																		{isSubContractor ?
																			<>
																				<div className="col-lg-6">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.subcontractor')}</p>
																						<div>{jobDetails.subcontractor_name ? jobDetails.subcontractor_name :""}</div>
																					</div>
																				</div>

																				<div className="col-lg-6">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.contact_name')}</p>
																						<div>{jobDetails.contact_name ? jobDetails.contact_name :""}</div>
																					</div>
																				</div>
																				<div className="col-lg-6">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.contact_email')}</p>
																						<div>{jobDetails.contact_email ? jobDetails.contact_email :""}</div>
																					</div>
																				</div>
																				<div className="col-lg-6">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.contact_phone')}</p>
																						<div>{jobDetails.contact_phone ? jobDetails.contact_phone :""}</div>
																					</div>
																				</div>
																			</>
																		:null}
																		{isSubContractor?
																			<>
																				<div className="col-lg-6">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.project_manager')}</p>
																						<div>{jobDetails.project_manager_name ? jobDetails.project_manager_name :""}</div>
																					</div>
																				</div>
																				<div className="col-lg-6">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.project_name')}</p>
																						<div>{jobDetails.project_name ? jobDetails.project_name :""}</div>
																					</div>
																				</div>
																				<div className="col-lg-6">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.daily_project_value')} ({CURRENCY_SYMBOL})</p>
																						<div>{jobDetails.daily_project_value ? jobDetails.daily_project_value :""}</div>
																					</div>
																				</div>
																			</>
																		:
																		<>
																			<div className="col-lg-6">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.date')}</p>
																					<div>{jobDetails.date ? formatDate(jobDetails.date, DATERANGE_DATE_TIME_FORMAT) :""}</div>
																				</div>
																			</div>
																			<div className="col-lg-6">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.foreman')}</p>
																					<div>{jobDetails.foreman_name ? jobDetails.foreman_name :""}</div>
																				</div>
																			</div>
																			<div className="col-lg-6">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.start_time')}</p>
																					<div>{jobDetails.start_time ? jobDetails.start_time :""}</div>
																				</div>
																			</div>
																			<div className="col-lg-6">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.end_time')}</p>
																					<div>{jobDetails.end_time ? jobDetails.end_time :""}</div>
																				</div>
																			</div>
																		</>
																		}
																	</div>
																</div>
																<div className="col-lg-6">
																	<div className="list-group-item full-view map-view-section">
																		{/* <p>Map</p> */}
																		<div className="map-view">
																			<GoogleMap ref={this.mapElementRef} dataList={jobList} />
																		</div>
																	</div>
																</div>
																{!isSubContractor?
																	<>
																		{jobDetails.crewMembers && jobDetails.crewMembers.length ?
																			<div className="col-lg-6">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.employees')}</p>
																					<div>
																						<ul>
																							{jobDetails.crewMembers.map((element, index) =>
																								<li className="assets-list-wrap" key={'job-details-crew-'+index} style={{backgroundColor: element.bgColor}}>
																									<img src={element.image} alt="crew-img"/>
																									<span>{element.groupName}</span>
																								</li>
																							)}
																						</ul>
																					</div>
																				</div>
																			</div>
																		:null}
																	</>
																:null}
																{jobDetails.assets && jobDetails.assets.length ?
																	<div className="col-lg-6">
																		<div className="list-group-item full-view">
																			<p>{translate('calendar.equipment')}</p>
																			<div>
																				<ul>
																					{jobDetails.assets.map((element, index) =>
																						<li className="assets-list-wrap" key={'job-details-asset-'+index} style={{backgroundColor: element.bgColor}}>
																							<img src={element.image} alt="crew-img"/>
																							<span dangerouslySetInnerHTML={{__html: element.groupName ? element.groupName :""}}></span>
																						</li>
																					)}
																				</ul>
																			</div>
																		</div>
																	</div>
																:null}
															</div>
														</div>
													</div>
												</div>
											</div>

										    {!isSubContractor ?
												<>
													<div className="accordion-item">
														<h2 className="accordion-header">
														<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseFour" aria-expanded="false" aria-controls="jobDetailsCollapseFour">
															{translate('calendar.scope_of_work')}
														</button>
														</h2>
														<div id="jobDetailsCollapseFour" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-3">
															<div className="accordion-body">
																<div className="list-group list-group-flush">
																	<div className="row">
																		<div className="col-lg-3">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.location')}</p>
																				<div>{jobDetails.location_title ? jobDetails.location_title :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-3">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.department')}</p>
																				<div>{jobDetails.department_title ? jobDetails.department_title :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-3">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.daily_project_value')} ({CURRENCY_SYMBOL})</p>
																				<div>{jobDetails.daily_project_value ? jobDetails.daily_project_value :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-3">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.total_cost')} ({CURRENCY_SYMBOL})</p>
																				<div>{jobDetails.total_cost ? CURRENCY_SYMBOL+' ' +customFixedValue(jobDetails.total_cost) :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-6">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.scope_of_work_type')}</p>
																				<div>
																					{jobDetails.scope_of_work_type_title ? jobDetails.scope_of_work_type_title :""}
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-6">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.scope_of_work')}</p>
																				<div className='nl2br'>
																					{jobDetails.scope_of_work ? jobDetails.scope_of_work :""}
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-12">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.materials')}</p>
																				<div className="nl2br">{jobDetails.materials ? jobDetails.materials :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-12">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.additional_equipment')}</p>
																				<div className="nl2br">
																					{jobDetails.additional_equipment ? jobDetails.additional_equipment :""}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>

													{!isSubContractor && jobDetails.department !== ELEC_DEPARTMENT && jobDetails.department !== OFFICE_DEPARTMENT ?
														<div className="accordion-item">
															<h2 className="accordion-header">
															<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseSeven" aria-expanded="false" aria-controls="jobDetailsCollapseSeven">
																{jobDetails.department_title ? jobDetails.department_title :translate('calendar.department')}
															</button>
															</h2>
															<div id="jobDetailsCollapseSeven" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-6">
																<div className="accordion-body">
																	<div className="list-group list-group-flush">
																		<div className="row">
																			{jobDetails.department === OSP_DEPARTMENT ?
																				<div className="col-lg-12">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.approved_line_items')}</p>
																						<div className="nl2br">{jobDetails.approved_line_items ? jobDetails.approved_line_items :""}</div>
																					</div>
																				</div>
																			:null}
																			{[OSP_DEPARTMENT, CTR_DEPARTMENT, HDD_DEPARTMENT].indexOf(jobDetails.department) !== -1  ?
																				<>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.is_locates_required')}</p>
																							<div>{jobDetails.is_locates_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																						</div>
																					</div>
																					{jobDetails.is_locates_required === ACTIVE ?
																						<>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.locate_type')}</p>
																									<div>{jobDetails.locate_type_title ? jobDetails.locate_type_title :""}</div>
																								</div>
																							</div>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.expiration_date')}</p>
																									<div>{jobDetails.locate_expiration_date ? formatDate(jobDetails.locate_expiration_date, DATERANGE_DATE_TIME_FORMAT) :""}</div>
																								</div>
																							</div>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.ticket_number')}</p>
																									<div>{jobDetails.ticket_number ? jobDetails.ticket_number :""}</div>
																								</div>
																							</div>
																						</>
																					:null}
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.is_flagger_required')}</p>
																							<div>{jobDetails.is_flagger_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																						</div>
																					</div>
																					{jobDetails.is_flagger_required === ACTIVE ?
																						<>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.subcontractor')}</p>
																									<div>{jobDetails.flagger_subcontractor_name ? jobDetails.flagger_subcontractor_name :""}</div>
																								</div>
																							</div>
																						</>
																					:null}
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.is_subcontractor_required')}</p>
																							<div>{jobDetails.is_subcontractor_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																						</div>
																					</div>
																					{jobDetails.is_subcontractor_required === ACTIVE ?
																						<>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.subcontractor')}</p>
																									<div>{jobDetails.subcontractor_name ? jobDetails.subcontractor_name :""}</div>
																								</div>
																							</div>
																						</>
																					:null}
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.is_inspector_required')}</p>
																							<div>{jobDetails.inspector_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																						</div>
																					</div>
																					{jobDetails.inspector_required === ACTIVE ?
																						<>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.inspector_name')}</p>
																									<div>{jobDetails.inspector_name ? jobDetails.inspector_name :""}</div>
																								</div>
																							</div>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.inspector_email')}</p>
																									<div>{jobDetails.inspector_email ? jobDetails.inspector_email :""}</div>
																								</div>
																							</div>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.inspector_phone')}</p>
																									<div>{jobDetails.inspector_phone ? jobDetails.inspector_phone :""}</div>
																								</div>
																							</div>
																						</>
																					:null}
																				</>
																			:null}

																			{jobDetails.department === ISP_DEPARTMENT ?
																				<>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.tco_date')}</p>
																							<div>{jobDetails.tco_date ? formatDate(jobDetails.tco_date, DATERANGE_DATE_TIME_FORMAT) :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.is_electricians_required')}</p>
																							<div>{jobDetails.is_electricians_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																						</div>
																					</div>
																				</>
																			:null}

																			{jobDetails.department === FIBER_DEPARTMENT ?
																				<>
																					<div className="col-lg-3">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.e2e_ticket')}</p>
																							<div>{jobDetails.e2e_ticket_number ? jobDetails.e2e_ticket_number :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-3">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.cm_ticket')}</p>
																							<div>{jobDetails.cm_ticket_number ? jobDetails.cm_ticket_number :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-3">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.fiber_task')}</p>
																							<div>{jobDetails.fiber_task_title ? jobDetails.fiber_task_title :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-3">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.hub_location')}</p>
																							<div>{jobDetails.headend_hub_location_title ? jobDetails.headend_hub_location_title :""}</div>
																						</div>
																					</div>
																					{jobDetails.fiber_task_other ?
																						<div className="col-lg-12">
																							<div className="list-group-item full-view">
																								<p>{translate('calendar.fiber_other_task')}</p>
																								<div>{jobDetails.fiber_task_other ? jobDetails.fiber_task_other :""}</div>
																							</div>
																						</div>
																					:null}
																				</>
																			:null}

																			{jobDetails.department === HDD_DEPARTMENT ?
																				<>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.drill_size')}</p>
																							<div>{jobDetails.hdd_drill_size_title ? jobDetails.hdd_drill_size_title :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.hdd_locator_type')}</p>
																							<div>{jobDetails.hdd_locator_type ? jobDetails.hdd_locator_type :""}</div>
																						</div>
																					</div>
																					{jobDetails.hdd_locator_type_other ?
																						<>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.hdd_locator_type_other')}</p>
																									<div>{jobDetails.hdd_locator_type_other ? jobDetails.hdd_locator_type_other :""}</div>
																								</div>
																							</div>
																						</>
																					:null}
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.length_of_bore')}</p>
																							<div>{jobDetails.hdd_length_of_bore ? jobDetails.hdd_length_of_bore :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.product_size')}</p>
																							<div>{jobDetails.hdd_product_size ? jobDetails.hdd_product_size :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.dump_site')}</p>
																							<div>{jobDetails.dump_site_location ? jobDetails.dump_site_location :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.water_source')}</p>
																							<div>{jobDetails.water_source_location ? jobDetails.water_source_location :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.is_potholing_required')}</p>
																							<div>{jobDetails.is_potholing_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																						</div>
																					</div>
																					{jobDetails.is_potholing_required === ACTIVE ?
																						<>
																							<div className="col-lg-4">
																								<div className="list-group-item full-view">
																									<p>{translate('calendar.surface_type')}</p>
																									<div>{jobDetails.surface_type_title ? jobDetails.surface_type_title :""}</div>
																								</div>
																							</div>
																						</>
																					:null}
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.is_tracer_wire_required')}</p>
																							<div>{jobDetails.is_tracer_wire_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																						</div>
																					</div>
																				</>
																			:null}

																			{jobDetails.department === CTR_DEPARTMENT ?
																				<>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.traffic_control_requirements')}</p>
																							<div className="nl2br">{jobDetails.traffic_control_requirements ? jobDetails.traffic_control_requirements :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.specialized_tools')}</p>
																							<div>{jobDetails.specialized_tools_ctr_title ? jobDetails.specialized_tools_ctr_title :""}</div>
																						</div>
																					</div>
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.final_inspection_status')}</p>
																							<div>{jobDetails.final_inspection_status === String(ACTIVE) ? translate('calendar.complete') :translate('calendar.Incomplete')}</div>
																						</div>
																					</div>
																				</>
																			:null}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													:null}

													<div className="accordion-item">
														<h2 className="accordion-header">
														<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseEight" aria-expanded="false" aria-controls="jobDetailsCollapseEight">
															{translate('calendar.other_details')}
														</button>
														</h2>
														<div id="jobDetailsCollapseEight" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-5">
															<div className="accordion-body">
																<div className="list-group list-group-flush">
																	<div className="row">
																		{!isSubContractor ?
																			<>
																				<div className="col-lg-4">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.is_permit_required')}</p>
																						<div>{jobDetails.is_permit_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																					</div>
																				</div>
																				<div className="col-lg-4">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.is_prevailing_wage')}</p>
																						<div>{jobDetails.is_prevailing_wage === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																					</div>
																				</div>
																				<div className="col-lg-4">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.is_out_of_town')}</p>
																						<div>{jobDetails.is_out_of_town === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																					</div>
																				</div>
																				<div className="col-lg-4">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.is_badge_required')}</p>
																						<div>{jobDetails.is_badge_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																					</div>
																				</div>
																				{jobDetails.is_badge_required === ACTIVE ?
																					<div className="col-lg-4">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.badges_required')}</p>
																							<div>{jobDetails.badges_required_title ? jobDetails.badges_required_title :""}</div>
																						</div>
																					</div>
																				:null}
																				<div className="col-lg-4">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.is_safety_orientation_required')}</p>
																						<div>{jobDetails.is_safety_orientation_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																					</div>
																				</div>
																				{jobDetails.is_out_of_town === ACTIVE ?
																					<div className="col-lg-12">
																						<div className="list-group-item full-view">
																							<p>{translate('calendar.lodging_information')}</p>
																							<div className="nl2br">{jobDetails.lodging_information ? jobDetails.lodging_information :""}</div>
																						</div>
																					</div>
																				:null}
																				<div className="col-lg-12">
																					<div className="list-group-item full-view">
																						<p>{translate('calendar.jobsite_restrictions')}</p>
																						<div className="nl2br">
																							{jobDetails.jobsite_restrictions ? jobDetails.jobsite_restrictions :""}
																						</div>
																					</div>
																				</div>
																			</>
																		:null}
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="accordion-item">
														<h2 className="accordion-header">
														<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseFive" aria-expanded="false" aria-controls="jobDetailsCollapseFive">
															{translate('calendar.poc')}
														</button>
														</h2>
														<div id="jobDetailsCollapseFive" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-4">
															<div className="accordion-body">
																<div className="list-group list-group-flush">
																	<div className="row">
																		<div className="col-lg-4">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.contact_name')}</p>
																				<div>{jobDetails.contact_name ? jobDetails.contact_name :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-4">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.contact_email')}</p>
																				<div>{jobDetails.contact_email ? jobDetails.contact_email :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-4">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.contact_phone')}</p>
																				<div>
																					{jobDetails.contact_phone ? jobDetails.contact_phone :""}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</>
											:null}

											<div className="accordion-item d-none">
												<h2 className="accordion-header">
												<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseOne" aria-expanded="true" aria-controls="jobDetailsCollapseOne">
													{translate('calendar.basic_details')}
												</button>
												</h2>
												<div id="jobDetailsCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-1">
													<div className="accordion-body">
														<div className="list-group list-group-flush">
															<div className="row">
																{!isSubContractor ?
																	<>
																		<div className="col-lg-3">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.location')}</p>
																				<div>{jobDetails.location_title ? jobDetails.location_title :""}</div>
																			</div>
																		</div>
																		<div className="col-lg-3">
																			<div className="list-group-item full-view">
																				<p>{translate('calendar.department')}</p>
																				<div>{jobDetails.department_title ? jobDetails.department_title :""}</div>
																			</div>
																		</div>
																	</>
																:null}
																<div className={isSubContractor ? "col-lg-4" :"col-lg-3"}>
																	<div className="list-group-item full-view">
																		<p>{translate('calendar.date')}</p>
																		<div>{jobDetails.date ? formatDate(jobDetails.date, DATERANGE_DATE_TIME_FORMAT) :""}</div>
																	</div>
																</div>
																<div className={isSubContractor ? "col-lg-4" :"col-lg-3"}>
																	<div className="list-group-item full-view">
																		<p>{translate('calendar.start_time')}</p>
																		<div>{jobDetails.start_time ? jobDetails.start_time :""}</div>
																	</div>
																</div>
																<div className={isSubContractor ? "col-lg-4" :"col-lg-3"}>
																	<div className="list-group-item full-view">
																		<p>{translate('calendar.end_time')}</p>
																		<div>{jobDetails.end_time ? jobDetails.end_time :""}</div>
																	</div>
																</div>
																{[ADMIN, PROJECT_MANAGER, DISPATCHER].indexOf(permissionLevel) !==  -1 ?
																	<>
																		{jobDetails.employee_cost ?
																			<div className="col-lg-3">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.emplyoee_cost')} ({CURRENCY_SYMBOL})</p>
																					<div>{jobDetails.employee_cost ?  CURRENCY_SYMBOL+' ' +customFixedValue(jobDetails.employee_cost) :""}</div>
																				</div>
																			</div>
																			: null
																		}
																		{jobDetails.equipment_cost ?
																			<div className="col-lg-3">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.equipment_cost')} ({CURRENCY_SYMBOL})</p>
																					<div>{jobDetails.equipment_cost ? CURRENCY_SYMBOL+' ' +customFixedValue(jobDetails.equipment_cost) :""}</div>
																				</div>
																			</div>
																		: null}
																		{jobDetails.total_cost ?
																			<div className="col-lg-3">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.total_cost')} ({CURRENCY_SYMBOL})</p>
																					<div>{jobDetails.total_cost ? CURRENCY_SYMBOL+' ' +customFixedValue(jobDetails.total_cost) :""}</div>
																				</div>
																			</div>
																		: null}
																	</>
																:null }
															</div>
														</div>
													</div>
												</div>
											</div>

											{isSubContractor ?
												<div className="accordion-item">
													<h2 className="accordion-header">
													<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseOneTwo" aria-expanded="true" aria-controls="jobDetailsCollapseOneTwo">
														{translate('calendar.additional_information')}
													</button>
													</h2>
													<div id="jobDetailsCollapseOneTwo" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-1">
														<div className="accordion-body">
															<div className="list-group list-group-flush">
																<div className="row">
																	<div className="col-lg-6">
																		<div className="list-group-item full-view">
																			<p>{translate('calendar.is_flagger_required')}</p>
																			<div>{jobDetails.is_flagger_required === ACTIVE ? translate('calendar.yes') :translate('calendar.no')}</div>
																		</div>
																	</div>
																	{jobDetails.is_flagger_required === ACTIVE ?
																		<>
																			<div className="col-lg-6">
																				<div className="list-group-item full-view">
																					<p>{translate('calendar.subcontractor')}</p>
																					<div>{jobDetails.flagger_subcontractor_name ? jobDetails.flagger_subcontractor_name :""}</div>
																				</div>
																			</div>
																		</>
																	:null}
																	<div className="col-lg-6">
																		<div className="list-group-item full-view">
																			<p>{translate('calendar.materials')}</p>
																			<div className="nl2br">{jobDetails.materials ? jobDetails.materials :""}</div>
																		</div>
																	</div>
																	<div className="col-lg-6">
																		<div className="list-group-item full-view">
																			<p>{translate('calendar.additional_equipment')}</p>
																			<div className="nl2br">
																				{jobDetails.additional_equipment ? jobDetails.additional_equipment :""}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											: null}

											<div className="accordion-item">
												<h2 className="accordion-header">
												<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseSix" aria-expanded="false" aria-controls="jobDetailsCollapseSix">
													{translate('calendar.attachment')}
												</button>
												</h2>
												<div id="jobDetailsCollapseSix" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-7">
													<div className="accordion-body">
														<div className="list-group list-group-flush attachment-section">
															<div className="row">
																<div className="col-lg-6">
																	<div className="list-group-item full-view">
																		<p>{translate('calendar.attachment')}</p>
																		<div className="table-responsive">
																			{jobDetails.attachment && jobDetails.attachment.length > 0 ? (
																				<table className="table attched-file-table">
																					<thead>
																						<tr>
																							<th>Uploaded Date</th>
																							<th>File Name</th>
																							<th></th>
																						</tr>
																					</thead>
																					<tbody>
																						{jobDetails.attachment.map(fileData => (
																							<tr key={fileData.id}>
																								<td>{jobDetails.created ? formatDate(jobDetails.created, DATERANGE_DATE_TIME_FORMAT) : "N/A"}</td>
																								<td>
																									{fileData.file_name && ALLOWED_IMAGE_EXTENSIONS.indexOf(fileData.file_name.split('.').pop().toLowerCase()) !== -1 ?
																										<img src={`${fileUrl}${fileData.name}`} alt="Img"  className="sort-name" onClick = {()=>{this.showImageLightbox(`${fileUrl}${fileData.name}`)}}/>
																									:
																										<a
																											href={`${fileUrl}${fileData.name}`}
																											target="_blank"
																											rel="noreferrer"
																											className="d-flex align-items-center">
																											<img src={downloadIcon} alt="Download" />
																											<span className="ms-1">{fileData.file_name}</span>
																										</a>
																									}
																								</td>
																								<td>
																									<button type="button" className="attched-delete-btn" onClick={(e) => {this.deleteAttachment(e,jobDetails._id,fileData.id)}} >
																										<img src={deleteIcon} alt="img" />
																									</button>
																								</td>
																							</tr>
																						))}
																					</tbody>
																				</table>
																			) : (
																				<div className="col-lg-4">
																					{"N/A"}
																				</div>
																			)}
																		</div>
																	</div>
																</div>

																<div className="col-lg-6">
																	<div className="list-group-item full-view">
																		<p>{translate('calendar.description')}</p>
																		<div className="nl2br">{jobDetails.notes ? jobDetails.notes :"N/A"}</div>
																	</div>
																</div>
															</div>
															{(eventDate >= currentDate) && (permissionLevel === FOREMAN || permissionLevel === PROJECT_MANAGER || permissionLevel === DISPATCHER || permissionLevel === ADMIN) && !isForemanDisable &&
																<>
																	<button type="button" className="job-detail-edit" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample" >
																		<img src={addPlusIcon} alt="img" />
																	</button>

																	<div className="collapse attchment-edit show" id="collapseExample">
																		<div className="card card-body">
																				<form onSubmit={(event) => this.updateAttachmentDetails(event, 'updateAttachment')} noValidate>
																					<h3>{translate("calendar.update_attachment_details")}</h3>
																					<div className="row">
																						<div className="col-lg-6">
																							<div className="form-group">
																								<label htmlFor="attachment" className="form-label">
																									{translate('calendar.attachment')}
																									<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip} >
																										<img src="/images/info-icon.svg" className='ms-1' alt="info-icon" />
																									</OverlayTrigger>
																								</label>
																								<div className="form-group custom-upload">
																									<label className="upload-source">
																										<input type="file" name='file' id="file" className="d-none" onChange={(event) => this.handleFormInputs(event, "image")} multiple />
																										<p>
																											{fields.image && fields.image.file_count ?
																												fields.image.file_count+ " Attachment"
																											:
																												<>
																													{translate("system.select_attachment")}
																													{/* <i className="error text-danger">*</i> */}
																												</>
																											}
																										</p>
																										<span>{translate('system.upload')}</span>
																									</label>
																								</div>
																								<span className="error text-danger">{this.state.errors["image"]}</span>

																							</div>
																						</div>
																						<div className="col-lg-6">
																							<div className="form-group">
																								<label htmlFor="notes" className="form-label">{translate('calendar.description')} </label>
																								<textarea type="notes" className="form-control" name='notes' id='notes' value={fields["notes"] ? fields["notes"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "notes")}></textarea>
																								<span className="error text-danger">{this.state.errors["notes"]}</span>
																							</div>
																						</div>
																					</div>
																					<div className="d-flex">
																						<button type="button" className="btn btn-fill" onClick={(e) => {this.updateAttachmentDetails(e,jobDetails._id)}}>
																							{translate('system.submit')} {submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
																						</button>
																						<button type="button" className="btn btn-outline ms-3" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" >
																							Cancel
																						</button>
																					</div>
																				</form>
																		</div>
																	</div>
																</>
															}
														</div>
													</div>
												</div>
											</div>
											{(permissionLevel === ADMIN || permissionLevel === PROJECT_MANAGER || permissionLevel === FOREMAN) && !isForemanDisable &&
												<div className="accordion-item crew-modal">
													<h2 className="accordion-header">
													<button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#jobDetailsCollapseNine" aria-expanded="false" aria-controls="jobDetailsCollapseNine">
														{translate('calendar.comments')}
													</button>
													</h2>
													<div id="jobDetailsCollapseNine" className="accordion-collapse collapse show" data-bs-parent="#jobDetailAccordion-8">
														<div className="accordion-body">
															<NoteBody job_id={jobDetails._id} />
														</div>
													</div>
												</div>
											}
										</div>
									}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-fill" onClick={()=> {this.props.closeJobDetailModal()} } >Close</button>
								</div>
							</div>
						</div>
					</div>

					{lightBoxShow &&
						<Lightbox
							mainSrc={lightBoxImgUrl}
							onCloseRequest={() => this.setState({ lightBoxShow: false })}
							toolbarButtons={[
								<button key="download" onClick={this.handleDownload}>
									<img src={downloadIcon} alt="Download" />
								</button>
							]}
						/>
					}

					<div className="modal-backdrop fade show"></div>
				</>
			:null}
		</>
	}
}
