import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import React from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import base64 from 'base-64';
import Cookies from 'universal-cookie';
import { confirmAlert } from 'react-confirm-alert';
import utf8 	from 'utf8';
import { PublicClientApplication } from "@azure/msal-browser";

import axiosInstance from '../config/axios_interceptor.js'
import { API_URL, ROUND_PRECISION, STATUS_SUCCESS, STATUS_ERROR, WEBSITE_URL }  from '../config/global_constants';
import { translate }  from './intl_helpers';

export async function callBackendAPI(request, source = {}, showError = true){
    let sourceToken =   (source.token) ? { cancelToken: source.token } : {};
	let response 	=   await axios.all(request.map(params => axiosInstance.post( API_URL+'api/'+ params.model + '/' + params.method , params, sourceToken)));
    let errorFlag 	=   false;
    let isLogout 	=   false;
    let errorRecord =   [];
    let apiResponse =   response.map( el => {
        let responseData = el.data;
        if(responseData.status === STATUS_ERROR && !errorFlag && !responseData.is_logout){
            errorFlag	= true;
            errorRecord = (responseData.message) ? responseData.message : [];
        }

        if(responseData.is_logout) isLogout = true;
        return responseData;
    });

    if(showError && errorFlag && errorRecord.length>0) reFormatErrors(errorRecord);

    /** Logout user */
    if(isLogout) return logout(isLogout);

    return {success: !errorFlag, data: apiResponse};
};

/* Call backend APIs by axios */
export async function callBackendAPIAndImageUpload(requestData, stopErrorScroll){
    const cookies 	=	new Cookies();
	let userDetails =	cookies.get('user_details');
    let tmpFormData =   requestData.fileData;
    let apiData     =   requestData.formData ? requestData.formData :{};

    let tmpHeaders = {"authkey": process.env.REACT_APP_API_HEADER_AUTH_KEY,'Content-Type': 'multipart/form-data'};
	if(userDetails && userDetails.token) tmpHeaders.authorization = userDetails.token;
    if(userDetails && userDetails._id) apiData.user_id = userDetails._id;

    // Convert the object to a JSON string
	const jsonString = utf8.encode(JSON.stringify(apiData));
	const base64String = base64.encode(jsonString);
    tmpFormData.append("data",base64String);

    let response = await axios.post( API_URL+'api/'+requestData.model+"/"+requestData.method,tmpFormData,{headers: tmpHeaders });

    let responseData = {};
    if(response.data){
        const binaryBuffer =  base64.decode(response.data);

        const decodedBytes = new Uint8Array(binaryBuffer.length);
        for(let i = 0; i < binaryBuffer.length; i++) {
            decodedBytes[i] = binaryBuffer.charCodeAt(i);
        }

        // Decode the Uint8Array to a JSON string
        const decodedJsonString = new TextDecoder().decode(decodedBytes);

        responseData =	JSON.parse(decodedJsonString);
    }

    if(responseData.status !== STATUS_SUCCESS && !stopErrorScroll) scrollToErrorDiv();

    return {
        success : true,
        data 	: responseData
    };
};// end callBackendAPI()

//handle Inputs
export async function handleFormInputs(e, field, state) {
    let fields 				=	state;
    let oldValues           =   (fields[field] && fields[field].value) ? fields[field].value :"";
	fields[field] 			= 	{};
    fields[field]["type"] 	= 	e.target.type;
    let value 				=	e.target.value;

    if(e.target.type === "password") value = value.trim();

    if(e.target.type === "select-multiple"){
        value	=	[];
        let options = e.target.options;
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected && options[i].value) {
                value.push(options[i].value);
            }
        }
    }else if(e.target.type === "file"){
        value = (e.target.files.length === 1) ? e.target.files[0] : e.target.files
        fields[field]["file_name"]  = value.name ? value.name :"";
        fields[field]["file_count"] = e.target.files.length;
    }

    if(oldValues && e.target.type === "checkbox"){
        let coreVal     =   e.target.value;
        let currentVal  =   (e.target.checked) ? value :"";

        if(oldValues){
            if(oldValues.constructor === Array && oldValues.length >0){
                if(!currentVal && oldValues.indexOf(coreVal) !== -1) {
                    oldValues.splice(oldValues.indexOf(coreVal),1)
                    currentVal = (oldValues.length ===1) ? oldValues[0] :oldValues;
                }else if(currentVal){
                    oldValues.push(currentVal);
                    currentVal = oldValues;
                }
            }else if(oldValues.constructor === String){
                if(currentVal){
                    let tmpArray = [oldValues,currentVal];
                    currentVal = tmpArray;
                }
            }
        }
        fields[field]["value"] = currentVal;
    }else{
        fields[field]["value"] = (e.target.type === "checkbox") ? ((e.target.checked) ? value :"") : value;
    }
    return fields;
}

export async function handleFormValidation(validations,state, params, sendToAPI = true, showSuccessFlash = true, stopErrorScroll){
    let fields 		=	state;
    let errors 		= 	[];
    let formIsValid = 	true;

    /** Validate form data */
    if(validations.constructor === Object && Object.keys(validations).length >0){
        Object.keys(validations).forEach(field =>{
            var value 			 =	(fields && fields[field] && typeof fields[field].value !== typeof undefined) ? fields[field].value :"";
            var validatorRules	 =	validations[field].rules;
            var fieldLabel		 =	validations[field].label.toLowerCase();
            var targetLabel		 =	validations[field].target_label ? validations[field].target_label.toLowerCase() :"";
            var targetFieldValue =	(field === "confirm_password" && fields.password && fields.password.value) ?  fields.password.value :"";
            var msg				 = 	validator(fieldLabel,validatorRules,value,targetFieldValue, targetLabel);

            if(msg){
                formIsValid  = false;
                errors.push({param :field, msg: msg});
            }
        });
    }

    /** Send error response **/
    if(!formIsValid){
        if(!stopErrorScroll) scrollToErrorDiv();
        return{"success": false, "errors": reFormatErrors(errors)};
    }

    /** Send succcess response **/
    if(!sendToAPI) return {"success": true};

	//put formIsValid in param of if for running below code
    if(formIsValid){
        let result 	= 	await callBackendAPI(params,false, false);
        if(result.data[0].status === STATUS_SUCCESS){
            /** Send success response **/
            return{"success": true, "msg": reFormatSuccess(result.data[0].message), "data":result.data[0]};
        }else{
            if(!stopErrorScroll) scrollToErrorDiv();

            /** Send error response **/
            return{"success": false, "errors": reFormatErrors(result.data[0].message), "data":result.data[0]};

        }
    }
}

function validator(field, rules, value,targetFieldValue, targetLabel){
    /** Basic validation message **/
    var messages = {
        require		:	translate("basic_validation.please_enter_field",null,field),
        select		:	translate("basic_validation.please_select_field",null,field),
        terms		:	translate("basic_validation.please_accept_field"),
        valid_location:	translate("basic_validation.please_select_valid_field"),
        integer	 	:	translate("basic_validation.field_must_be_number"),
        year		:	translate("basic_validation.field_must_be_formatted_as_a_year"),
        decimal		:	translate("basic_validation.field_must_be_numeric_or_more_than_one"),
        date		:	translate("basic_validation.field_must_be_formatted_as_a_date"),
        email		:	translate("basic_validation.please_enter_valid_email"),
        url			:	translate("basic_validation.field_must_be_formatted_as_url"),
        number		:	translate("basic_validation.field_should_be_numeric"),
        tooLong		:	translate("basic_validation.field_cannot_be_longer_than_max_characters"),
        tooShort	:	translate("basic_validation.field_length_should_be_digits_long",null,field),
        equal		:	translate("basic_validation.field_should_be_same_as_target_field",null,field),
        email_mobile:	translate("basic_validation.please_enter_valid_email_mobile_number"),
        mobile_number:	translate("basic_validation.please_enter_valid_mobile_number"),
        otp         :	translate("basic_validation.please_enter_valid_otp"),
        iframe      :	translate("basic_validation.please_enter_valid_iframe"),
        alphabet    :	translate("basic_validation.field_must_be_alphabet"),
        float       :	translate("basic_validation.field_must_be_numeric_or_greater_than_0"),
    };

    /** Basic validation regex **/
    var patterns ={

        year		:	/^\d{4}$/,
        integer		:	/^[0-9]+$/,
        alphabet	:	/^[a-zA-Z ]+$/,
        decimal		:	/^[1-9]\d*(\.\d+)?$/,
        // email_mobile:	/\^\(\[a-zA-Z0-9_\\\.\\-\]\)\+\\@\(\(\[a-zA-Z0-9\\-\]\)\+\\\.\)\+\(\[a-zA-Z0-9\]\{2,4\}\)\|\(\[0-9\]\{10\}\)\+\$/,
        // email		:	/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        email		:	/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/,
        url			:	/\^\(\(\[\^<>\(\)\\\[\\\]\\\.,;:\\s@\\"\]\+\(\\\.\[\^<>\(\)\\\[\\\]\\\.,;:\\s@\\"\]\+\)\*\)\|\(\\"\.\+\\"\)\)@\(\(\[\^<>\(\)\[\\\]\\\.,;:\\s@\\"\]\+\\\.\)\+\[\^<>\(\)\[\\\]\\\.,;:\\s@\\"\]\{2,\}\)\$/i,
        // url			:	/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
        mobile_number	:	/^([0-9]{10})+$/,
        iframe	    :	/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g,
        float		:	/^(?!0\d)\d*(\.\d+)?$/,

    };

    var msg = "";
    if(!value || value.constructor === String){
        value	= (value) ? value.trim() :"";
        for(var i = 0; i < rules.length; i++){
            var validationMethod = rules[i];
            switch(validationMethod){
                case "required":
                    if(!value) msg = messages.require;
                    break;
                case "select":
                    if(!value) msg = messages.select;
                    break;
                case "valid_location":
                    if(!value) msg = messages.valid_location;
                    break;
                case "email":
                    if(!patterns.email.test(value)) msg = messages.email;
                    break;
                case "email_mobile":
                    if(!patterns.email_mobile.test(value)) msg = messages.email_mobile;
                    break;
                case "alphabet":
                    if(!patterns.alphabet.test(value)) msg = messages.alphabet;
                    break;
                case "mobile_number":
                    if(!patterns.mobile_number.test(value)) msg = messages.mobile_number;
                    break;
                case "minLength":
                    console.log("messages.tooShort ",messages.tooShort)
                    if(value.length < 6) msg = messages.tooShort;
                    break;
                case "equal":
                    field = field.charAt(0).toUpperCase() + field.slice(1);
                    if(value !== targetFieldValue)  msg = messages.equal.replace(/target_field/, (targetLabel ? targetLabel :"password"));
                    break;
                case "integer":
                    if(!patterns.integer.test(value)) msg = messages.integer;
                    break;
                case "decimal":
                    if(!patterns.decimal.test(value)) msg = messages.decimal;
                    break;
                case "year":
                    if(!patterns.year.test(value)) msg = messages.year;
                    break;
                case "otp":
                    if(value.length < 6) msg = messages.otp;
                    break;
                case "terms":
                    if(!value) msg = messages.terms;
                    break;
                case "url":
                    if(!patterns.url.test(value)) msg = messages.url;
                break;
                case "iframe":
                    if(!patterns.float.test(value)) msg = messages.float;
                break;
                case "float":
                    if(!patterns.float.test(value)) msg = messages.float;
                break;
                default:
                    break;
            }
            if(msg) break;
        };
    };
    field = (field.replace(/_/g," "));
    return ucfirst(msg.replace(/{field}/,field));
}// end validator();

export function reFormatErrors(errorArray){
    var errorObj = {};
    if(errorArray && errorArray.constructor === Array && errorArray.length >0){
        errorArray.forEach(records=>{
            if(records.param && records.msg){
                if(records.param === "invalid-access"){
                    notice('error',records.msg);
                }else if(records.param === STATUS_ERROR){
                    notice('error',records.msg);
                }else{
                    errorObj[records.param]	 = records.msg;
                }
            }
        });
    }else if(errorArray && errorArray.constructor === String){
        notice('error',errorArray);
    }
    return errorObj;
}// end reFormatErrors();

export function reFormatSuccess(successArray){
    var successObj = {};
    if(successArray && successArray.constructor === Array && successArray.length >0){
        successArray.forEach(records=>{
            if(records.param && records.msg){
                if(records.param === STATUS_SUCCESS && records.msg !== STATUS_SUCCESS){
                    notice('success',records.msg);
                }else{
                    successObj[records.param] = records.msg;
                }
            }
        });
    }else{
        notice('success',successArray);
    }
    return successObj;
}// end reFormatSuccess();

export async function notice(type,message,timeout,displayPosition,showHideTransition){
    displayPosition 	= (displayPosition === "undefined") 	? 	displayPosition 	:toast.POSITION.TOP_RIGHT;
    showHideTransition 	= (showHideTransition === "undefined")	?	showHideTransition	:"fade";
    timeout 			= (timeout === "undefined") 			?	timeout 			:10000;

    toast.dismiss();
    if (message !== "" && message !== undefined) {
        if(type === STATUS_SUCCESS) toast.success(message, { position: displayPosition });
        if(type === STATUS_ERROR) toast.error(message, { position: displayPosition });
    }
}// end notice()


export function ucfirst(s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1);
}// end ucfirst()

export function upperCaseOfName(firstName, lastName) {
    if (typeof firstName !== 'string' || typeof lastName !== 'string') return '';

    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
}//end upperCaseOfName

const msalConfig = {
    auth: {
        clientId    :   process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        authority   :   "https://login.microsoftonline.com/" + process.env.REACT_APP_AZURE_AD_TENANT,
        redirectUri :   WEBSITE_URL+"login",
        postLogoutRedirectUri :   WEBSITE_URL,
    },
    cache: {
        cacheLocation           :   "sessionStorage",
        storeAuthStateInCookie  :   true,
    },
    system: {
        loggerOptions: {
            logLevel: "info",
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
            },
        },
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

/** Logout the user account **/
export async function logout(isShowMsg){
    const cookies 	=	new Cookies();
    let userDetails = 	cookies.get('user_details');
    let userId      =   (userDetails && userDetails._id) ? userDetails._id :"";
    let loginWithSso=   (userDetails && userDetails.login_with_sso) ? userDetails.login_with_sso :"";

    window.showLoader();

    if(userId) await callBackendAPI([{model: 'registration', method:'logOut', user_id: userId }],{},false);

    try{
        if(loginWithSso && loginWithSso !== ""){
            msalInstance.logoutRedirect({
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
            });
        }

        /** Remove cookies  */
        cookies.remove("user_details",{ path: '/' });
        window.sessionStorage.clear();
        window.hideLoader();
    }catch(e){
        /** Remove cookies  */
        cookies.remove("user_details",{ path: '/' });
        window.sessionStorage.clear();
        window.hideLoader();
    }
    window.location.href = "/login";
    return;
}// end logout()

/** For confirm box */
export function customConfirmBox(message,title,showExtraButton=false){
    return new Promise((resolve) => {
        let btnArr = [
            {
                label: translate("system.yes"),
                className: "btn btn-primary w-25 d-inline",
                onClick: () => {resolve(true)}
            },
            {
                label: translate("system.no"),
                className: "rounded-pill w-25",
                onClick: () => {resolve(false)}
            }
        ]
        if(showExtraButton){
            btnArr = [
                {
                    label: (
                        <div>
                            <img src="/images/alert_move_icon.svg" alt="move" style={{ marginRight: '8px' }} />
                            {translate("system.move")}
                        </div>
                    ),
                    onClick: () => {resolve("move")}
                },
                {
                    label: (
                        <div>
                            <img src="/images/clone.svg" alt="clone" style={{ marginRight: '8px' }} />
                            {translate("system.clone")}
                        </div>
                    ),
                    onClick: () => {resolve("clone")}
                },

                {
                    label: translate("system.cancel"),
                    className: "rounded-pill w-25",
                    onClick: () => {resolve(false)}
                }
            ]
        }
        if(!title) title = translate("system.are_you_sure");
        confirmAlert({
            title: "",
            message: title+(message ? ", "+String(message).toLowerCase() :""),
            buttons: btnArr
        });
    });
}// end customConfirmBox()

/**
 *  Function to Round the number
 *
 * @param value		As Number To be round
 * @param precision As Precision
 *
 * @return number
 */
export  function customRound(value, precision){
	try{
		if(!value || isNaN(value)){
			return value;
		}else{
			precision = (typeof precision != typeof undefined) ? precision :ROUND_PRECISION;
			var multiplier = Math.pow(10, precision || 0);
			return Math.round(value * multiplier) / multiplier;
		}
	}catch(e){
		return value;
	}
}// end round()

/**
 * For scroll to first error div
 *
 *@param null
*
* @return null
*/
export function scrollToErrorDiv(){
    console.log("scrollToErrorDiv")
    setTimeout(function(){
        let errorDiv = document.getElementsByClassName("error");
        let isHave = false;
        for (var i = 0; i < errorDiv.length; i++) {
            if(!isHave && document.getElementsByClassName("error")[i].innerHTML){
                isHave = true;
                let hei = document.getElementsByClassName("error")[i].getBoundingClientRect().top + window.scrollY-80;
                window.scroll({top: hei,behavior: 'smooth'});
            }
        }
    },100)
    return;
}// end scrollToErrorDiv();

/**
 * For scroll to top
 *
 *@param null
*
* @return null
*/
export function scrollToTop(elementId){
    setTimeout(function(){
        if(elementId){
            if(document.getElementById(elementId)){
                let hei = document.getElementById(elementId).getBoundingClientRect().top + window.scrollY-80;
                window.scroll({top: hei,behavior: 'smooth'});
            }
        }else{
            window.scrollTo(-100, 0);
        }
    },10);
    return;
}// end scrollToErrorDiv();

/**
 * Function for make string to title case
 *
 * @param str AS String
 *
 * @return string
 */
export function toTitleCase(str){
	return str.replace(/\w\S*/g,(txt)=>{return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}//end toTitleCase();

/**
 * Function for manage calendar shift start or end time
 *
 * @param isEnd AS boolean
 *
 * @return string
 */
export function manageShiftTime(isEnd, shiftTime){
    let tmpTime     = 	shiftTime ? shiftTime.split(":") :[];
    let initHour    =   isEnd ? 14 :6;
    let initMin     =   30;
    let tmpHour	    =	parseInt(tmpTime[0] && tmpTime[0] >= 0 ? tmpTime[0] :initHour);
    let tmpMin	    =	parseInt(tmpTime[1] && tmpTime[1] >= 0 ? tmpTime[1] :initMin);

	return new Date(new Date().setHours(tmpHour, tmpMin, 0, 0));
}//end manageShiftTime;

/**
 * Function for manage calendar shift start or end time
 *
 * @param isEnd AS boolean
 *
 * @return string
 */
export function manageCTRShiftTime(isEnd, shiftTime, isCtr){
    let tmpTime     = 	shiftTime ? shiftTime.split(":") :[];
    let initHour    =   isEnd ? 17 :7;
    let initMin     =   0;
    let tmpHour	    =	parseInt(tmpTime[0] && tmpTime[0] >= 0 ? tmpTime[0] :initHour);
    let tmpMin	    =	parseInt(tmpTime[1] && tmpTime[1] >= 0 ? tmpTime[1] :initMin);

	return new Date(new Date().setHours(tmpHour, tmpMin, 0, 0));
}//end manageShiftTime;


/**
 * Function for manage call external js
 *
 * @param isEnd AS boolean
 *
 * @return string
 */
export function loadExternalJs(scriptUrl,scriptId,callback){
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.id = scriptId;
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback(existingScript);
        };
    }
    if (existingScript && callback) callback(existingScript);
}

export function getValueByObjectOrArray(key, dataSet, setKey, targetKey){
    let tempVal = key;
    if(dataSet){
        if(dataSet.constructor === Array && dataSet.length){
            dataSet.forEach((item)=>{
                if(item[setKey] && item[setKey] === key && item[targetKey]){
                    tempVal = item[targetKey];
                }
            });
        }else if(dataSet.constructor === Object && Object.keys(dataSet).length){
            Object.keys(dataSet).forEach(itemKey=>{
                if(dataSet[itemKey]  && dataSet[targetKey] && dataSet[itemKey] === key){
                    tempVal = dataSet[targetKey];
                }
            });
        }
    }
    return tempVal;
}

export function calculatePercentage(num, total) {
    if (total === 0)
        return 0;
    let percentage = Math.floor((num / total) * 100); // Round down to nearest integer
    return percentage;
}

export function getRandomString(length = 3) {
    const characters = '123456789abcdefghijklmnopqrstuvwxyz';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

// CustomInput Component for datepicker
export const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <input
      className='form-control'
      ref={ref}
      value={value || ''}
      onClick={onClick}
      readOnly
      placeholder={placeholder}
      style={{ cursor: 'pointer' }}
    />
));

export function isWeekendDay(date){
    const start = new Date(date);
    const dayOfWeek = start.getDay();
    if((dayOfWeek === 6 || dayOfWeek === 0)){
        return true;
    }else{
        return false;
    }
}

export function convertTo12HourFormat(time24) {
    // Split the time into hours and minutes
    let [hours, minutes] = time24.split(':').map(Number);
    
    // Determine the period (AM/PM) and adjust hours
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    // Format minutes to always have two digits
    minutes = minutes < 10 ? '0' + minutes : minutes;
    
    // Combine hours, minutes, and period into the final string
    return `${hours}:${minutes} ${period}`;
}

/**
 *  Function to Round the number
 *
 * @param value		As Number To be round
 * @param precision As Precision
 *
 * @return number
 */
export  function customFixedValue(value, precision){
	try{
		if(!value || isNaN(value)){
			return value;
		}else{
			precision = (typeof precision != typeof undefined) ? precision :ROUND_PRECISION;
			return value.toFixed(precision);
		}
	}catch(e){
		return value;
	}
}// end value()

