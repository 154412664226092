import React, { Component } from 'react';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import {AsyncPaginate} from 'react-select-async-paginate';
import Cookies from 'universal-cookie';

import EllipsisPagination from './../Pagination';
import { callBackendAPI, handleFormInputs, getValueByObjectOrArray} from './../../helpers/common';

import { translate, formatDate, getCurrentWeekDates } from './../../helpers/intl_helpers';

import { STATUS_SUCCESS, PAGING_LENGTH_MENU, DATE_RANGE_PICKER_FORMAT, DEPARTMENT_DROPDOWN, DATERANGE_DATE_TIME_FORMAT, FIBER_DEPARTMENT, EXPORT_CSV, EXPORT_PDF, CALENDAR_DATE_TIME_FORMAT } from './../../config/global_constants';

import closeIcon 	 from './../../assets/images/close_icon.svg';

let defaultSelectedFields = ["start_date","end_date","location","department","job_number","work_order_number","jobsite_address","project_manager_id","foreman_id"];
let jobFields = [
	{ value: 'start_date', label: translate('job_report.start_date'), sortable: true},
	{ value: 'end_date', label: translate('job_report.end_date'), sortable: true},
	{ value: 'location', label: translate('job_report.location') },
	{ value: 'department', label: translate('job_report.department') },
	{ value: 'job_number', label: translate('job_report.job_number'), sortable: true },
	{ value: 'work_order_number', label: translate('job_report.work_order'), sortable: true },
	{ value: 'jobsite_address', label: translate('job_report.jobsite_address'), sortable: true },
	{ value: 'project_manager_id', label: translate('job_report.project_manager') },
	{ value: 'foreman_id', label: translate('job_report.foreman') },
	{ value: 'start_time', label: translate('job_report.start_time'), sortable: true  },
	{ value: 'end_time', label: translate('job_report.end_time'), sortable: true  },
	{ value: 'scope_of_work_type', label: translate('job_report.scope_of_work_type')},
	{ value: 'scope_of_work', label: translate('job_report.scope_of_work'), sortable: true},
	{ value: 'project_name', label: translate('job_report.project_name'), sortable: true },
	{ value: 'crew_size', 	label:  translate('job_report.crew_size'), sortable: true },
	{ value: 'employee_ids', label: translate('job_report.employees') },
	{ value: 'equipment_ids', label: translate('job_report.equipments') },
	{ value: 'jobsite_address_street', label: translate('job_report.jobsite_address_street'), sortable: true },
	{ value: 'jobsite_address_city', label: translate('job_report.jobsite_address_city'), sortable: true },
	{ value: 'jobsite_address_zip', label:  translate('job_report.jobsite_address_zip'), sortable: true },
	{ value: 'jobsite_address_state', label: translate('job_report.jobsite_address_state'), sortable: true },
	{ value: 'is_permit_required', label: translate('job_report.is_permit_required'), sortable: true },
	{ value: 'is_prevailing_wage', label: translate('job_report.is_prevailing_wage'), sortable: true },
	{ value: 'is_out_of_town', label: translate('job_report.is_out_of_town'), sortable: true },
	{ value: 'lodging_information', label: translate('job_report.lodging_information') },
	{ value: 'is_badge_required', label: translate('job_report.is_badge_required'), sortable: true },
	{ value: 'badges_required', label: translate('job_report.badges_required') },
	{ value: 'is_safety_orientation_required', label: translate('job_report.is_safety_orientation_required'), sortable: true },
	{ value: 'daily_project_value', label: translate('job_report.daily_project_value'), sortable: true },
	{ value: 'materials', label: translate('job_report.materials'), sortable: true },
	{ value: 'jobsite_restrictions', label:   translate('job_report.jobsite_restrictions'), sortable: true },
	{ value: 'additional_equipment', label:   translate('job_report.additional_equipment'), sortable: true },
	{ value: 'contact_name', label:  translate('job_report.point_of_contact_name'), sortable: true },
	{ value: 'contact_email', label: translate('job_report.point_of_contact_email'), sortable: true },
	{ value: 'contact_phone', label: translate('job_report.point_of_contact_phone'), sortable: true },
	{ value: 'notes', label:  translate('job_report.notes'), sortable: true },
	{ value: 'approved_line_items', label: translate('job_report.approved_line_items'), sortable: true },
	{ value: 'is_locates_required', label: translate('job_report.is_locates_required'), sortable: true },
	{ value: 'locate_type', label: translate('job_report.locate_type') },
	{ value: 'ticket_number', label: translate('job_report.locate_ticket_number'), sortable: true },
	{ value: 'locate_expiration_date', label: translate('job_report.locate_expiration_date'), sortable: true },
	{ value: 'is_flagger_required', label: translate('job_report.is_flagger_required'), sortable: true },
	{ value: 'flagger_subcontractor_id', label: translate('job_report.flagger_subcontractor_id') },
	{ value: 'is_subcontractor_required', label: translate('job_report.is_subcontractor_required'), sortable: true },
	{ value: 'subcontractor_id', label: translate('job_report.subcontractor') },
	{ value: 'inspector_required', label: translate('job_report.inspector_required'), sortable: true },
	{ value: 'inspector_name', label: translate('job_report.inspector_name'), sortable: true },
	{ value: 'inspector_email', label: translate('job_report.inspector_email'), sortable: true },
	{ value: 'inspector_phone', label: translate('job_report.inspector_phone'), sortable: true },
	{ value: 'e2e_ticket_number', label: translate('job_report.e2e_ticket_number'), sortable: true },
	{ value: 'cm_ticket_number', label: translate('job_report.cm_ticket_number'), sortable: true },
	{ value: 'fiber_task_other', label: translate('job_report.fiber_task_other'), sortable: true },
	{ value: 'fiber_task', label: translate('job_report.fiber_task') },
	{ value: 'headend_hub_location', label: translate('job_report.headend_hub_location'), sortable: true },
	{ value: 'hdd_drill_size', label: translate('job_report.hdd_drill_size') },
	{ value: 'hdd_locator_type', label: translate('job_report.hdd_locator_type') },
	{ value: 'hdd_locator_type_other', label: translate('job_report.hdd_locator_type_other') },
	{ value: 'hdd_length_of_bore', label: translate('job_report.hdd_length_of_bore') },
	{ value: 'hdd_product_size', label: translate('job_report.hdd_product_size') },
	{ value: 'dump_site_location', label: translate('job_report.dump_site_location') },
	{ value: 'water_source_location', label: translate('job_report.water_source_location'), sortable: true },
	{ value: 'is_potholing_required', label: translate('job_report.is_potholing_required'), sortable: true },
	{ value: 'surface_type', label: translate('job_report.surface_type') },
	{ value: 'is_tracer_wire_required', label: translate('job_report.is_tracer_wire_required'), sortable: true },
	{ value: 'tco_date', label: translate('job_report.tco_date'), sortable: true },
	{ value: 'is_electricians_required', label: translate('job_report.is_electricians_required'), sortable: true },
	{ value: 'po_number', label: translate('job_report.po_number'), sortable: true },
	{ value: 'po_value', label: translate('job_report.po_value'), sortable: true },
	{ value: 'traffic_control_requirements', label: translate('job_report.traffic_control_requirements'), sortable: true },
	{ value: 'specialized_tools_ctr', label: translate('job_report.specialized_tools_ctr') },
	{ value: 'final_inspection_status', label: translate('job_report.final_inspection_status'), sortable: true },
	{ value: 'subject', label: translate('job_report.subject'), sortable: true },
];

class JobList extends Component {

	constructor(props) {
        super(props)

        this.state = {
			rowsPerPage		: 	PAGING_LENGTH_MENU[0],
			selectedFields	: 	defaultSelectedFields,
			// defaultDateRange: 	getDefaultDateRange(),
			// searchStats 	: 	{date_range :getDefaultDateRange()},
			defaultDateRange: 	[getCurrentWeekDates().monday, getCurrentWeekDates().friday],
			searchStats 	: 	{date_range :[getCurrentWeekDates().monday, getCurrentWeekDates().friday]},
            jobList			: 	[],
			totalRecords	: 	0,
			totalPages		:	0,
			isLoading		:	true,
			currentPage		:	1,
			locationList	:	[],
			sortStats		:	{},
        };

		this.anchorRef 				=	React.createRef();
		this.getJobList    			=	this.getJobList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.handlePageLengthChange	=	this.handlePageLengthChange.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.manageSortingEvent		=	this.manageSortingEvent.bind(this);
    }

	componentDidMount() {
		this.getCookiesData().then(()=>{
			this.getMasterList();
        	this.getJobList();
		});

    }

	/**
     * For get cookies details
     */
	 getCookiesData = async () => {
        const cookies = new Cookies();
        const userCookie = cookies.get('user_details');
        const { department, location } = userCookie;
		const finalDepartment =  ( (department && department.length) ? DEPARTMENT_DROPDOWN.filter(data => department.includes(data.value)) :null);

		// this.setState({
		// 	searchStats: {
		// 		...this.state.searchStats,
		// 		location	: location || [],
		// 		department	: finalDepartment
		// 	}
		// });
    }
	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : ''};
            if(type === "projectManager")  	apiReq.is_project_manager   =   true;
            if(type === "foreMan")  		apiReq.is_foreman   		=   true;
            if(type === "crewMember")  		apiReq.is_crew_member   	=   true;
            if(type === "subcontractor")  	apiReq.is_subcontractor 	=   true;

			if(type === "jobNumber") apiReq = {model: 'calendar', method: 'cannonJobList', job_number: inputValue ? inputValue : ''};

			if(type === "workOrder"){
				let jobArr = [];

				if(this.state.searchStats.job_number && this.state.searchStats.job_number.length) 	  jobArr	= this.state.searchStats.job_number.map(element => element.value);

				if(this.state.searchStats.job_number.length === 0) return {options: [], hasMore: true, additional: {page: page, type: type }};

				apiReq = {model: 'calendar', method: 'workOrderList', job_id: jobArr, work_order_number: inputValue ? inputValue : '' };
			}

			if(type === "fiberTask") apiReq = { model: 'home', method: 'masterData', type: "fiber_task", name: inputValue ? inputValue : ''};
			if(type === "asset") 	 apiReq	= { model: 'calendar', method: 'assetsList', asset_name: inputValue ? inputValue : ''};

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For manage page records limit stats
	*
	* @param lengthOpt as selected limit option params
	*
	* @return null
	*/
	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getJobList();
			});
		}
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getJobList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({searchStats: {date_range: this.state.defaultDateRange}, currentPage: 1 },()=>{
			this.getJobList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()


	handleChange = (fieldName) => (fieldVal) => {
		if(fieldName === "selectedFields"){
			let tmpArray = [];
			if(fieldVal){
				fieldVal = fieldVal.constructor !== Array  ? [fieldVal] :fieldVal;

				tmpArray = fieldVal.map(element=>{ return element.value });
			}

			if(tmpArray.length >= 5) this.setState({selectedFields: tmpArray});
		}else{
			let newObj = {[fieldName]: fieldVal};
			let updatedFields = {...this.state.searchStats, ...newObj};

			if(fieldName === "location" && fieldVal.length === 0) 	updatedFields['department'] = null;
			if(fieldName === "job_number") 	updatedFields['work_order_number'] = null;

			this.setState({searchStats: updatedFields});
		}
	};

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location,
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	 * Manage sort event
	 */
	manageSortingEvent = (event, field, dir) => {
		event.preventDefault();

		this.setState({
			sortStats	: {key: field, dir: dir},
			currentPage	: 1,
		},()=>{
			this.getJobList();
		});
	};

	/**
	* For get job listing
	*/
	getJobList = () => {
		const { rowsPerPage, currentPage, searchStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let skip 	=	(currentPage-1)*rowsPerPage;
			let apiReq 	=	{ model: 'reports', method: 'jobList', length: rowsPerPage, start: skip};
			if(searchStats && Object.keys(searchStats).length){
				apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
				// apiReq.foreman_id 			=	searchStats.foreman_id ? searchStats.foreman_id.value :"";
				// apiReq.employee_id 			=	searchStats.employee_id ? searchStats.employee_id.value :"";
				// apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
				// apiReq.project_manager_id	=	searchStats.project_manager_id ? searchStats.project_manager_id.value :"";
				// apiReq.work_order_id 		=	searchStats.work_order_number ? searchStats.work_order_number.value :"";
				// apiReq.job_number_id 		=	searchStats.job_number ? searchStats.job_number.value :"";
				apiReq.fiber_task 			=	searchStats.fiber_task ? searchStats.fiber_task.value :"";
				// apiReq.subcontractor_id 	=	searchStats.subcontractor_id ? searchStats.subcontractor_id.value :"";
				// apiReq.location 			=	searchStats.location ? searchStats.location.value :"";
				// apiReq.department			=	searchStats.department ? searchStats.department.value :"";
				apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
				apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
				apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";

				let locArr    	= [];
				let deptArr   	= [];
				let pmArr     	= [];
				let foremanArr  = [];
				let empArr  	= [];
				let equipArr  	= [];
				let subArr  	= [];
				let jobArr  	= [];
				let workArr  	= [];

				if(searchStats.location && searchStats.location.length) 	  	locArr	 	= searchStats.location.map(element => element.value);
				if(searchStats.department && searchStats.department.length) 	deptArr  	= searchStats.department.map(element => element.value);
				if(searchStats.foreman_id && searchStats.foreman_id.length) 	foremanArr  = searchStats.foreman_id.map(element => element.value);
				if(searchStats.employee_id && searchStats.employee_id.length) 	empArr  	= searchStats.employee_id.map(element => element.value);
				if(searchStats.asset_id && searchStats.asset_id.length) 		equipArr  	= searchStats.asset_id.map(element => element.value);
				if(searchStats.job_number && searchStats.job_number.length) 		jobArr  	= searchStats.job_number.map(element => element.value);
				if(searchStats.work_order_number && searchStats.work_order_number.length) 		workArr  	= searchStats.work_order_number.map(element => element.value);

				if(searchStats.subcontractor_id && searchStats.subcontractor_id.length) 		subArr = searchStats.subcontractor_id.map(element => element.value);
				if(searchStats.project_manager_id && searchStats.project_manager_id.length) 	pmArr  = searchStats.project_manager_id.map(element => element.value);

				if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
				if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
				if(searchStats.foreman_id && searchStats.foreman_id.length)  	apiReq.foreman_id  	=   foremanArr;
				if(searchStats.employee_id && searchStats.employee_id.length)  	apiReq.employee_id  =   empArr;
				if(searchStats.asset_id && searchStats.asset_id.length)  		apiReq.asset_id  	=   equipArr;
				if(searchStats.job_number && searchStats.job_number.length)  	apiReq.job_number_id  	=   jobArr;
				if(searchStats.work_order_number && searchStats.work_order_number.length)  		apiReq.work_order_id	  	=   workArr;

				if(searchStats.subcontractor_id && searchStats.subcontractor_id.length)  		apiReq.subcontractor_id  	=   subArr;
				if(searchStats.project_manager_id && searchStats.project_manager_id.length)  	apiReq.project_manager_id  	=   pmArr;
			}

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						jobList 	:	response.data[0].result,
						totalRecords: 	response.data[0].recordsTotal,
						totalPages	: 	Math.ceil(response.data[0].recordsTotal/rowsPerPage),
						isLoading	: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getJobList()

	/**
	* For export PTO listing
	*/
	exportTableContent = (fileType) => {
		const {searchStats, selectedFields } = this.state;


		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let apiReq = {model: 'reports', method: 'exportJobList', file_type: fileType, fields: selectedFields};
		if(searchStats && Object.keys(searchStats).length){
			let locArr    = [];
			let deptArr   = [];
			let pmArr     	= [];
			let foremanArr  = [];
			let empArr  	= [];
			let equipArr  	= [];
			let subArr  	= [];
			let jobArr  	= [];
			let workArr  	= [];

			apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0],CALENDAR_DATE_TIME_FORMAT) :"";
			apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1],CALENDAR_DATE_TIME_FORMAT) :"";
			// apiReq.foreman_id 			=	searchStats.foreman_id ? searchStats.foreman_id.value :"";
			// apiReq.employee_id 			=	searchStats.employee_id ? searchStats.employee_id.value :"";
			// apiReq.asset_id 			=	searchStats.asset_id ? searchStats.asset_id.value :"";
			// apiReq.project_manager_id	=	searchStats.project_manager_id ? searchStats.project_manager_id.value :"";
			// apiReq.work_order_id 		=	searchStats.work_order_number ? searchStats.work_order_number.value :"";
			// apiReq.job_number_id 		=	searchStats.job_number ? searchStats.job_number.value :"";
			apiReq.fiber_task 			=	searchStats.fiber_task ? searchStats.fiber_task.value :"";
			// apiReq.subcontractor_id 	=	searchStats.subcontractor_id ? searchStats.subcontractor_id.value :"";
			apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
			apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
			apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";
			if(searchStats.location && searchStats.location.length) 	  	locArr	 = searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) 	deptArr  = searchStats.department.map(element => element.value);

			if(searchStats.foreman_id && searchStats.foreman_id.length) 	foremanArr  = searchStats.foreman_id.map(element => element.value);
			if(searchStats.employee_id && searchStats.employee_id.length) 	empArr  	= searchStats.employee_id.map(element => element.value);
			if(searchStats.asset_id && searchStats.asset_id.length) 		equipArr  	= searchStats.asset_id.map(element => element.value);
			if(searchStats.job_number && searchStats.job_number.length) 		jobArr  	= searchStats.job_number.map(element => element.value);
			if(searchStats.work_order_number && searchStats.work_order_number.length) 		workArr  	= searchStats.work_order_number.map(element => element.value);

			if(searchStats.subcontractor_id && searchStats.subcontractor_id.length) 		subArr = searchStats.subcontractor_id.map(element => element.value);
			if(searchStats.project_manager_id && searchStats.project_manager_id.length) 	pmArr  = searchStats.project_manager_id.map(element => element.value);



			if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
			if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
			if(searchStats.foreman_id && searchStats.foreman_id.length)  	apiReq.foreman_id  	=   foremanArr;
			if(searchStats.employee_id && searchStats.employee_id.length)  	apiReq.employee_id  =   empArr;
			if(searchStats.asset_id && searchStats.asset_id.length)  		apiReq.asset_id  	=   equipArr;
			if(searchStats.job_number && searchStats.job_number.length)  	apiReq.job_number_id  	=   jobArr;
			if(searchStats.work_order_number && searchStats.work_order_number.length)  		apiReq.work_order_id	  	=   workArr;

			if(searchStats.subcontractor_id && searchStats.subcontractor_id.length)  		apiReq.subcontractor_id  	=   subArr;
			if(searchStats.project_manager_id && searchStats.project_manager_id.length)  	apiReq.project_manager_id  	=   pmArr;
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					this.anchorRef.current.href 	=	response.data[0].file_url;
					this.anchorRef.current.download = 	response.data[0].file_name;

					// Programmatically click the anchor element
					this.anchorRef.current.click();
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	render() {
		const {jobList, searchStats, currentPage, rowsPerPage, totalPages, isLoading,locationList, selectedFields,} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("reports.job_list")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src="/images/filter.svg" alt="Img" width={29} height={26} />
						</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="work_order_number">{translate('reports.date')}</label>
													<div className="col-12">
														<DateRangePicker
															value={searchStats.date_range ? searchStats.date_range :null}
															format={DATE_RANGE_PICKER_FORMAT}
															size="lg"
															editable={false}
															className='form-control input-with-label'
															onChange={this.handleChange("date_range")}
															placeholder={translate('reports.search_by_date')}
															onClean={()=>{ this.handleChange("date_range",[]) }}
														/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('reports.location')}</label>

													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_location')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('reports.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="project_manager_id">{translate('reports.project_manager')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="project_manager_id"
														value={searchStats.project_manager_id  ? searchStats.project_manager_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("project_manager_id")}
														additional={{ page: 1, type: "projectManager"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_project_manager')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_foreMan">{translate('reports.foreman')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_foreMan"
														value={searchStats.foreman_id  ? searchStats.foreman_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("foreman_id")}
														additional={{ page: 1, type: "foreMan"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_foreman')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_employee">{translate('reports.employee')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_employee"
														value={searchStats.employee_id  ? searchStats.employee_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("employee_id")}
														additional={{ page: 1, type: "crewMember"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_employee')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_subcontractor">{translate('reports.subcontractor')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_subcontractor"
														value={searchStats.subcontractor_id  ? searchStats.subcontractor_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("subcontractor_id")}
														additional={{ page: 1, type: "subcontractor"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_subcontractor')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_equipment">{translate('reports.equipment')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_equipment"
														value={searchStats.asset_id  ? searchStats.asset_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("asset_id")}
														additional={{ page: 1, type: "asset"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_equipment')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="job_number">{translate('reports.job_number')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_job_number"
														key={searchStats.job_number  ? searchStats.job_number : null}
														value={searchStats.job_number  ? searchStats.job_number : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("job_number")}
														additional={{ page: 1, type: "jobNumber"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_job_number')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="work_order_number">{translate('reports.work_order_number')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_work_order_number"
														key={searchStats.job_number  ? searchStats.job_number : null}
														value={searchStats.work_order_number  ? searchStats.work_order_number : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("work_order_number")}
														additional={{ page: 1, type: "workOrder"}}
														isMulti
														isClearable={true}
														placeholder={translate('reports.search_by_work_order_number')}
													/>
												</div>
											</div>

											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_state" className="form-label mb-1">{translate('reports.state')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_state' placeholder={translate('reports.search_by_state')} id='search_by_state' value={searchStats["state"] ? searchStats["state"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"state")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_city" className="form-label mb-1">{translate('reports.city')}</label>
													<input type="text" className="form-control 	input-with-label" name='search_by_city' placeholder={translate('reports.search_by_city')} id='search_by_city' value={searchStats["city"] ? searchStats["city"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"city")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_zip" className="form-label mb-1">{translate('reports.zip')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_zip')} id='search_by_zip' value={searchStats["zip"] ? searchStats["zip"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "zip")} />
												</div>
											</div>

											{searchStats.department && searchStats.department.value === FIBER_DEPARTMENT ?
                                                <div className="col-lg-4">
                                                    <div className="form-group">
														<label className='mb-1 form-label' htmlFor="fiber_task">{translate('reports.fiber_task')}</label>
                                                        <AsyncPaginate
                                                            className="custom-react-select"
                                                            inputId="fiber_task"
                                                            value={searchStats.fiber_task  ? searchStats.fiber_task : null}
                                                            loadOptions={this.getDropDownList}
                                                            onChange={this.handleChange("fiber_task")}
                                                            additional={{ page: 1, type: "fiberTask"}}
															isClearable={true}
                                                            placeholder={translate('reports.search_by_fiber_task')}
                                                        />
                                                    </div>
                                                </div>
                                            :null}
											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<div className="form-group d-flex align-items-center table-count">
								{translate("system.show")}
								<Select
									className="custom-react-select mx-1"
									options={PAGING_LENGTH_MENU.map(key=>{return {value: key, label: key }})}
									value={{label:rowsPerPage, value:rowsPerPage}}
									closeMenuOnSelect={true}
									onChange={this.handlePageLengthChange}
								/>
								{translate("system.entries")}
							</div>
						</div>
						<div className="col-lg-9 text-end my-md-0 my-3">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
								<a href="/" ref={this.anchorRef} className='d-none' target='_new'>{translate("reports.export_as_csv")}</a>
							</div>
						</div>
						<div className="col-lg-12 mt-4">
							<div className="form-group">
								<Select
									className="custom-react-select form-control input-with-label table-multi-select"
									inputId="search_by_location"
									value={jobFields.filter(element => selectedFields.includes(element.value))}
									options={jobFields}
									onChange={this.handleChange("selectedFields")}
									closeMenuOnSelect={true}
									isMulti={true}
									placeholder={translate('reports.search_by_location')}
								/>
							</div>
						</div>
					</div>
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									{selectedFields.map((key, colIndex)=>
										<th key={"head-column-"+colIndex} width='10%'>
											{getValueByObjectOrArray(key, jobFields, "value","label")}
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{jobList && jobList && jobList.length  ?
									jobList.map((row, rowIndex)=>
										<tr key={"row"+rowIndex}>
											{selectedFields.map((key, keyIndex)=>
												<td key={"row-value"+rowIndex+keyIndex} className={[
													"notes",
													"scope_of_work",
													"materials",
													"additional_equipment",
													"approved_line_items",
													"traffic_control_requirements",
													"lodging_information",
													"jobsite_restrictions"
												].indexOf(key) !== -1 ? 'nl2br' :''}>
													{
														row[key] && ["start_date","end_date","locate_expiration_date",'tco_date'].indexOf(key) !== -1 ?
															formatDate(row[key], DATERANGE_DATE_TIME_FORMAT)
														:
														row[key] && key === "department" ?
															getValueByObjectOrArray(row[key], DEPARTMENT_DROPDOWN, "value","label")
														:
														row[key] ? row[key] : "-"
													}
												</td>
											)}
										</tr>
									)
								:(!isLoading ?
									<tr>
										<td colSpan={selectedFields.length} className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								:
									<tr>
										<td colSpan={selectedFields.length} className='text-center'>
											<img src="/images/pagination_loader.gif" alt="loading-icon" />
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
					{jobList.length && totalPages >1 ?
						<div className='d-flex justify-content-center align-items-center'>
							<EllipsisPagination currentPage={currentPage} totalPages={totalPages} onChange={this.handleChangePage} />
						</div>
					:null}
				</div>
			</div>
		</>
	}
}
export default JobList;
