import React, { Component } from 'react';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import {AsyncPaginate} from 'react-select-async-paginate';
import Cookies from 'universal-cookie';


import { callBackendAPI, handleFormInputs} from './../helpers/common';
import { translate, formatDate } from './../helpers/intl_helpers';
import { STATUS_SUCCESS, DATE_RANGE_PICKER_FORMAT, DEPARTMENT_DROPDOWN, CALENDAR_DATE_TIME_FORMAT} from './../config/global_constants';

import closeIcon from './../assets/images/close_icon.svg';
import GoogleMap from './../elements/GoogleMap';

class JobMap extends Component {

	constructor(props) {
        super(props)

        this.state = {
			defaultDateRange: 	[subDays(new Date(), 6), new Date()],
			searchStats 	: 	{date_range :[subDays(new Date(), 6), new Date()]},
            jobList			: 	[],
			locationList	:	[],
			isLoading		: 	true
        };

		this.mapElementRef 			=	React.createRef();
		this.getJobList    			=	this.getJobList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
    }

	componentDidMount() {

		this.getCookiesData().then(()=>{
			this.getMasterList();
			this.getJobList();
		});
	}

	/**
     * For get cookies details
     */
	getCookiesData = async () => {
		const cookies = new Cookies();
		const userCookie = cookies.get('user_details');
		const { department, location } = userCookie;
		const finalDepartment =  ( (department && department.length) ? DEPARTMENT_DROPDOWN.filter(data => department.includes(data.value)) :null);

		// this.setState({
		// 	searchStats: {
		// 		...this.state.searchStats,
		// 		location	: location || [],
		// 		department	: finalDepartment
		// 	}
		// });
	}

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : ''};
            if(type === "projectManager")  	apiReq.is_project_manager   =   true;
            if(type === "foreMan")  		apiReq.is_foreman   		=   true;
            if(type === "subcontractor")  	apiReq.is_subcontractor 	=   true;

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getJobList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({searchStats: {date_range: this.state.defaultDateRange}, currentPage: 1 },()=>{
			this.getJobList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()

	handleChange = (fieldName) => (fieldVal) => {
		let newObj = {[fieldName]: fieldVal};
		let updatedFields = {...this.state.searchStats, ...newObj};

		if(fieldName === "location" && fieldVal.length ===0) 	updatedFields['department'] = null;
		if(fieldName === "job_number") 	updatedFields['work_order_number'] = null;

		this.setState({searchStats: updatedFields});
	};

	/**
	* For get master listing
	*/
	getMasterList = () => {
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result){
				this.setState({locationList: response.data[0].result.location});
			}
		}).catch(err => console.log(err));
	}

	/**
	* For get job listing
	*/
	getJobList = () => {
		window.showLoader();
		const {searchStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let apiReq 	=	{ model: 'dashboard', method: 'getJobsByZip'};
			if(searchStats && Object.keys(searchStats).length){
				apiReq.from_date 			=	searchStats.date_range && searchStats.date_range[0] ? formatDate(searchStats.date_range[0], CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.to_date 				=	searchStats.date_range && searchStats.date_range[1] ? formatDate(searchStats.date_range[1], CALENDAR_DATE_TIME_FORMAT) :"";
				apiReq.foreman_id 			=	searchStats.foreman_id ? searchStats.foreman_id.value :"";
				apiReq.project_manager_id	=	searchStats.project_manager_id ? searchStats.project_manager_id.value :"";
				apiReq.city 				=	searchStats.city ? searchStats.city.value :"";
				apiReq.state 				=	searchStats.state ? searchStats.state.value :"";
				apiReq.zip 					=	searchStats.zip ? searchStats.zip.value :"";

				let locArr    = [];
            	let deptArr   = [];
				if(searchStats.location && searchStats.location.length) 	  	locArr	 = searchStats.location.map(element => element.value);
				if(searchStats.department && searchStats.department.length) 	deptArr  = searchStats.department.map(element => element.value);
				if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
				if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
			}

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				window.hideLoader();
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						jobList  :	response.data[0].result,
						isLoading: 	false
					},()=>{
						this.mapElementRef.current.markerInitiated();
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getJobList()

	render() {
		const {searchStats, jobList, locationList} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("jobmap.jobs_map")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src="/images/filter.svg" alt="Img" width={29} height={26} />
						</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('reports.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_location')}
													/>

												</div>
											</div>


											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('reports.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>


												</div>
											</div>


											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="project_manager_id">{translate('reports.project_manager')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="project_manager_id"
														value={searchStats.project_manager_id  ? searchStats.project_manager_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("project_manager_id")}
														additional={{ page: 1, type: "projectManager"}}
														isClearable={true}
														placeholder={translate('reports.search_by_project_manager')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_foreMan">{translate('reports.foreman')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_foreMan"
														value={searchStats.foreman_id  ? searchStats.foreman_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("foreman_id")}
														additional={{ page: 1, type: "foreMan"}}
														isClearable={true}
														placeholder={translate('reports.search_by_foreman')}
													/>
												</div>
											</div>


											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_state" className="form-label">{translate('reports.state')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_state' placeholder={translate('reports.search_by_state')} id='search_by_state' value={searchStats["state"] ? searchStats["state"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"state")} />
												</div>
											</div>

											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_city" className="form-label">{translate('reports.city')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_city')} id='search_by_city' value={searchStats["city"] ? searchStats["city"]["value"] : ""} onChange={(event) => this.handleFormInputs(event,"city")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label htmlFor="search_by_zip" className="form-label">{translate('reports.zip')}</label>
													<input type="text" className="form-control input-with-label" name='search_by_city' placeholder={translate('reports.search_by_zip')} id='search_by_zip' value={searchStats["zip"] ? searchStats["zip"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "zip")} />
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="work_order_number">{translate('reports.date')}</label>
													<div className="col-12">
														<DateRangePicker
															value={searchStats.date_range ? searchStats.date_range :null}
															format={DATE_RANGE_PICKER_FORMAT}
															size="lg"
															className='form-control input-with-label'
															editable={false}
															onChange={this.handleChange("date_range")}
															placeholder={translate('reports.search_by_date')}
															onClean={()=>{ this.handleChange("date_range",[]) }}
														/>
													</div>
												</div>
											</div>

											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<GoogleMap ref={this.mapElementRef} dataList={jobList} />
				</div>
			</div>
		</>
	}
}
export default JobMap;
