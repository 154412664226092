import React, { Component } from 'react';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import {AsyncPaginate} from 'react-select-async-paginate';
import Cookies from 'universal-cookie';


import { callBackendAPI} from './../../helpers/common';

import { translate, formatDate, getDatesBetween, getCurrentWeekDates } from './../../helpers/intl_helpers';

import { STATUS_SUCCESS, PAGING_LENGTH_MENU, DATE_RANGE_PICKER_FORMAT, DEPARTMENT_DROPDOWN, REPORT_DATE_FORMAT, EXPORT_CSV, EXPORT_PDF, CALENDAR_DATE_TIME_FORMAT, JOB_TITLE_TYPE_DROPDOWN } from './../../config/global_constants';

import closeIcon from './../../assets/images/close_icon.svg';

class ptoList extends Component {

	constructor(props) {
        super(props)

        this.state = {
			rowsPerPage		: 	PAGING_LENGTH_MENU[0],
			headerDateRange	: 	getDatesBetween(getCurrentWeekDates().monday, getCurrentWeekDates().friday),
			defaultDateRange: 	[getCurrentWeekDates().monday, getCurrentWeekDates().friday],
			searchStats 	: 	{date_range: [getCurrentWeekDates().monday, getCurrentWeekDates().friday] },
            ptoList			: 	[],
			isLoading		:	true,
			currentPage		:	1,
			locationList	:	[],
        };

		this.anchorRef 				=	React.createRef();
		this.getPTOList    			=	this.getPTOList.bind(this);
		this.exportPTOList    		=	this.exportPTOList.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.handlePageLengthChange	=	this.handlePageLengthChange.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
    }

	componentDidMount() {

		this.getCookiesData()
		.then(()=>{
			this.getPTOList();
        	this.getMasterList();
		});

    }

	/**
     * For get cookies details
     */
	 getCookiesData = async () => {
        const cookies = new Cookies();
        const userCookie = cookies.get('user_details');
        const { department, location } = userCookie;
		const finalDepartment =  ( (department && department.length) ? DEPARTMENT_DROPDOWN.filter(data => department.includes(data.value)) :null);

		// this.setState({
		// 	searchStats: {
		// 		...this.state.searchStats,
		// 		location	: location || [],
		// 		department	: finalDepartment
		// 	}
		// });
    }

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location,
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : ''};
            if(type === "crewMember")  apiReq.is_crew_member = true;

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For manage page records limit stats
	*
	* @param lengthOpt as selected limit option params
	*
	* @return null
	*/
	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getPTOList();
			});
		}
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getPTOList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({searchStats: {date_range: this.state.defaultDateRange}, currentPage: 1 },()=>{
			this.getPTOList();
		});
	};

	handleChange = (fieldName) => (fieldVal) => {

		if(fieldName === "export"){
			let tmpVal = fieldVal.value;

			this.exportPTOList(tmpVal);
		}else{
			let newObj = {[fieldName]: fieldVal};
			let updatedFields = {...this.state.searchStats, ...newObj};
			if(fieldName === "location" && fieldVal.length ===0) 	updatedFields['department'] = null;
			this.setState({searchStats: updatedFields});
		}
	};

	/**
	* For get PTO listing
	*/
	getPTOList = () => {
		const { rowsPerPage, currentPage, searchStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let tmpStartDate 	=	searchStats.date_range && searchStats.date_range[0] ? searchStats.date_range[0] :"";
			let tmpEndDate		= 	searchStats.date_range && searchStats.date_range[1] ? searchStats.date_range[1] :"";
			let skip 			=	(currentPage-1)*rowsPerPage;
			let apiReq = {model: 'reports', method: 'ptoReport', length: rowsPerPage, start: skip, start_date: formatDate(tmpStartDate,CALENDAR_DATE_TIME_FORMAT), end_date: formatDate(tmpEndDate,CALENDAR_DATE_TIME_FORMAT)};

			if(searchStats && Object.keys(searchStats).length){
				let locArr    = [];
                let deptArr   = [];
				let typeArr   = [];
				if(searchStats.location && searchStats.location.length) 	  	locArr	 = searchStats.location.map(element => element.value);
                if(searchStats.department && searchStats.department.length) 	deptArr  = searchStats.department.map(element => element.value);
				if(searchStats.job_type && searchStats.job_type.length) 		typeArr  = searchStats.job_type.map(element => element.value);

				if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
                if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
				if(searchStats.job_type && searchStats.job_type.length)  		apiReq.job_type  	=   typeArr;

				apiReq.employee_id 	=	searchStats.employee_id ? searchStats.employee_id.value :"";

			}

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						headerDateRange	: 	getDatesBetween(tmpStartDate, tmpEndDate),
						ptoList 		:	response.data[0].result,
						isLoading		: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getPTOList()

	/**
	* For export PTO listing
	*/
	exportPTOList = (fileType) => {
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let tmpStartDate 	=	searchStats.date_range && searchStats.date_range[0] ? searchStats.date_range[0] :"";
		let tmpEndDate		= 	searchStats.date_range && searchStats.date_range[1] ? searchStats.date_range[1] :"";
		let apiReq 			=	{model: 'reports', method: 'exportPtoReport', start_date: formatDate(tmpStartDate,CALENDAR_DATE_TIME_FORMAT), end_date: formatDate(tmpEndDate,CALENDAR_DATE_TIME_FORMAT), file_type: fileType};
		if(searchStats && Object.keys(searchStats).length){
			let locArr    = [];
            let deptArr   = [];
			let typeArr   = [];
			apiReq.employee_id 	=	searchStats.employee_id ? searchStats.employee_id.value :"";
			if(searchStats.location && searchStats.location.length) 	  	locArr	 = searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) 	deptArr  = searchStats.department.map(element => element.value);
			if(searchStats.job_type && searchStats.job_type.length) 		typeArr  = searchStats.job_type.map(element => element.value);

			if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
			if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
			if(searchStats.job_type && searchStats.job_type.length)  		apiReq.job_type  	=   typeArr;

		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					this.anchorRef.current.href 	=	response.data[0].file_url;
					this.anchorRef.current.download = 	response.data[0].file_name;

					// Programmatically click the anchor element
					this.anchorRef.current.click();
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportPTOList()

	getTableBody  = (currentIndex, date, isUnpaid)=>{
		const {ptoList} = this.state;
		let html = "";
		if(ptoList && ptoList.length && date){
			ptoList.forEach((row, rowIndex)=>{
				if(row.date && formatDate(row.date,REPORT_DATE_FORMAT) === formatDate(date, REPORT_DATE_FORMAT)){
					if(isUnpaid && row.unpaid_employee_list && row.unpaid_employee_list.length){
						row.unpaid_employee_list.forEach((data, dataIndex)=>{
							html += `<li key="row-${rowIndex+"-"+currentIndex+'-'+dataIndex}">${data.full_name}</li>`;
						});
					}else if(!isUnpaid && row.employee_list && row.employee_list.length){
						row.employee_list.forEach((data, dataIndex)=>{
							html += `<li key="row-${rowIndex+"-"+currentIndex+'-'+dataIndex}">${data.full_name}</li>`;
						});
					}
				}
			});
		}
		return html ? "<ul>"+html+"</ul>" :"-";
	}

	render() {
		const {ptoList, searchStats, isLoading, defaultDateRange, headerDateRange, locationList} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("reports.pto_report")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src="/images/filter.svg" alt="Img" width={29} height={26} />
						</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="date_range">{translate('reports.date')}</label>
													<div className="col-12 timeoff-date-picker">
														<DateRangePicker
															value={searchStats.date_range ? searchStats.date_range :null}
															format={DATE_RANGE_PICKER_FORMAT}
															size="lg"
															editable={false}
															onChange={this.handleChange("date_range")}
															placeholder={translate('reports.search_by_date')}
															onClean={()=>{ this.setState({searchStats: {...searchStats,...{date_range: defaultDateRange}}}) }}
														/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('reports.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_location')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('reports.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_employee">{translate('reports.employee')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_employee"
														value={searchStats.employee_id  ? searchStats.employee_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("employee_id")}
														additional={{ page: 1, type: "crewMember"}}
														isClearable={true}
														placeholder={translate('reports.search_by_employee')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_job_type">{translate('reports.search_by_job_type')}</label>
													<Select
														className="custom-react-select"
														options={JOB_TITLE_TYPE_DROPDOWN}
														closeMenuOnSelect={true}
														placeholder={translate("calendar.all_job_type")}
														value={searchStats.job_type ? searchStats.job_type :null}
														onChange={this.handleChange("job_type")}
														isMulti
														isClearable={true}
													/>
												</div>
											</div>

											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row">
						<div className="col-lg-12 text-end">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportPTOList(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportPTOList(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
								<a href="/" ref={this.anchorRef} className='d-none' target='_new'>{translate("reports.export_as_csv")}</a>
							</div>
						</div>
					</div>
					<div className="table-responsive theme-table">
						<Table className="employee-table-list" bordered responsive>
							<thead>
								<tr>
									<th width="15%">{translate("reports.date")}</th>
									<th>{translate("calendar.paid_employees")}</th>
									<th>{translate("calendar.unpaid_employees")}</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan={headerDateRange.length} className='text-center'>
											<img src="/images/pagination_loader.gif" alt="loading-icon" />
										</td>
									</tr>
								: !ptoList.length ?
									<tr>
										<td colSpan={headerDateRange.length} className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								:
									headerDateRange.map((date, rowIndex)=>
										<tr key={"row"+rowIndex}>
											<td width="20%"> {formatDate(date, REPORT_DATE_FORMAT)} </td>
											<td>
												<span dangerouslySetInnerHTML={{__html: this.getTableBody(rowIndex, date)}}></span>
											</td>
											<td>
												<span dangerouslySetInnerHTML={{__html: this.getTableBody(rowIndex, date, true)}}></span>
											</td>
										</tr>
									)
								}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</>
	}
}
export default ptoList;
