import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from 'react';
import { Table} from 'react-bootstrap';
import { NavLink as Link, withRouter} from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import {AsyncPaginate} from 'react-select-async-paginate';

import EllipsisPagination from './Pagination';
import { callBackendAPI, handleFormInputs} from './../helpers/common';

import { translate, formatDate } from './../helpers/intl_helpers';

import { STATUS_SUCCESS, PAGING_LENGTH_MENU, DATE_PICKER_DATE_FORMAT, DATE_RANGE_PICKER_FORMAT,EXPORT_PDF,EXPORT_CSV, CALENDAR_DATE_TIME_FORMAT, REPORT_DATE_FORMAT } from './../config/global_constants';

import closeIcon 	 from '../assets/images/close_icon.svg';
import dataTable 	 from '../assets/images/data-table.svg';
import dataAsc 		 from '../assets/images/data-asc.svg';
import dataDesc 	 from '../assets/images/data-desc.svg';
import filterSvg 	 from '../assets/images/filter.svg';
import moreSvg 		 from '../assets/images/more.svg';
import editIconSvg 	 from '../assets/images/edit-icon-white.svg';
import deleteIconSvg from '../assets/images/delete-icon-white.svg';
// import uploadBtn 	 from '../assets/images/upload.png';

class NormalDataTable extends Component {
	constructor(props) {
		super(props);

		let tmpStartDate 	=	subDays(new Date(), 6);
		let tmpEndDate		= 	new Date();

		const { pageStats } =	this.props;
		let isDefault  		=	{};
		let defaultLimit    =   (pageStats.pageType && (pageStats.pageType === "equipement" || pageStats.pageType === "employee")) ? PAGING_LENGTH_MENU[4] : PAGING_LENGTH_MENU[0];

		if(pageStats.columns && pageStats.columns.length){
			pageStats.columns.forEach(element => {
				if(element.selector && element.defaultSort && element.direction){
					isDefault = {key: element.selector, dir: element.direction};
				}
			});
		}

		let tmpSearchStats = pageStats.searchStats ? pageStats.searchStats :{};
		if(pageStats.search && pageStats.search.length){
			pageStats.search.forEach(element => {
				if(element.input_type && element.showDefaultRange && element.input_type === "date_range"){
					tmpSearchStats[element.selector] = [tmpStartDate, tmpEndDate];
				}else if(element.defaultValue){
					tmpSearchStats[element.selector] = element.defaultValue;
				}
			});
		}

		this.state = {
			list 		:	[],
			sortStats 	:	isDefault,
			actionIndex	:	0,
			isLoading	: 	true,
			currentPage	: 	1,
			totalRecords: 	0,
			rowsPerPage	: 	defaultLimit,
			totalPages	: 	1,
			searchStats : 	tmpSearchStats,
			completeApiRes : {},
			defaultDateRange: [tmpStartDate, tmpEndDate],
		};

		this.getTableList 			= 	this.getTableList.bind(this);
		this.handleChangePage 		=	this.handleChangePage.bind(this);
		this.openActionBtn	 		=	this.openActionBtn.bind(this);
		this.manageSortingEvent		=	this.manageSortingEvent.bind(this);
		this.handlePageLengthChange	=	this.handlePageLengthChange.bind(this);
		this.handleFormInputs 		=	this.handleFormInputs.bind(this);
		this.resetSearchStats 		=	this.resetSearchStats.bind(this);
		this.handleDateChange 		=	this.handleDateChange.bind(this);
	}

	getTableList = () => {
		const { apiParm, pageStats } = this.props;
		const { rowsPerPage, currentPage, sortStats, searchStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let skip 	=	(currentPage-1)*rowsPerPage;
			let apiReq 	=	{...apiParm, ...{limit: rowsPerPage, start: skip}};
			if(sortStats && sortStats.key) apiReq = {...apiReq, ...{sort_by: sortStats.key, sort_direction: sortStats.dir} };

			if(searchStats && Object.keys(searchStats).length){
				let tmpSearchObj = {};
				Object.keys(searchStats).forEach(key=>{
					if(searchStats[key]) {
						if(searchStats[key] instanceof Date) {
							tmpSearchObj[key] = searchStats[key].toISOString();
						}
						if(searchStats[key].value) {
							tmpSearchObj[key] = searchStats[key].value;
						}else if(pageStats.search && pageStats.search.length){
							pageStats.search.forEach(element => {
								if(element.selector === key && element.input_type === "date_range"){
									tmpSearchObj["start_"+key] 	=	searchStats[key][0] ? formatDate(searchStats[key][0],CALENDAR_DATE_TIME_FORMAT) :"";
									tmpSearchObj["end_"+key] 	= 	searchStats[key][1] ? formatDate(searchStats[key][1],CALENDAR_DATE_TIME_FORMAT) :"";
								}
								if(element.isMultiple && searchStats[key].length && (element.input_type === "select" || element.input_type === "asyncPaginate")){
									searchStats[key].forEach(subEle =>{
										if(subEle.value){
											if(!tmpSearchObj[key]) tmpSearchObj[key] = [];
											tmpSearchObj[key].push(subEle.value);
										}
									});
								}
							});
						}
                    }
				});

				apiReq = { ...apiReq, ...tmpSearchObj };
			}

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						list 		:	response.data[0].result,
						totalRecords: 	response.data[0].recordsTotal,
						totalPages	: 	Math.ceil(response.data[0].recordsTotal/rowsPerPage),
						isLoading	: 	false,
						completeApiRes : response.data[0]
					});
				}else if(pageStats && pageStats.back_url){
					this.props.history.push(pageStats.back_url);
				}
			}).catch(err => console.log(err));
		});
	};// end getTableList()

	componentDidMount() {
        this.getTableList();
    }

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()

	/**
	* For update state fields values when change select box
	*
	* @param fieldName 	as current field name
	* @param fieldVal  as current field value
	*
	* @return null
	*/
	searchSelectChange = (fieldName) => (fieldVal) => {
		const { pageStats } = this.props;
		let updateStats = {...this.state.searchStats, [fieldName]: fieldVal};

		/** Set null value in child select box when parent select box value changed */
		if(pageStats && pageStats.search && pageStats.search.length){
			pageStats.search.forEach(element=>{
				if(element.selector === fieldName && element.child_selector){
					updateStats[element.child_selector] = null;
				}
			});
		}

		this.setState({searchStats: updateStats});
	};// end searchSelectChange()

	resetSearchStats = () => {
		this.setState({
			searchStats	: {},
			currentPage	: 1,
		},()=>{
			this.getTableList();
		});
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage) => {
		this.setState({currentPage: newPage},()=>{
			this.getTableList();
		});
	};

	openActionBtn = (colIndex) => {
		this.setState({
			actionIndex: this.state.actionIndex !== colIndex ? colIndex : null
		});
	};

	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getTableList();
			});
		}
	};

	manageSortingEvent = (event, isSortable , field, dir) => {
		event.preventDefault();
		if(isSortable){
			this.setState({
				sortStats	: {key: field, dir: dir},
				currentPage	: 1,
			},()=>{
				this.getTableList();
			});
		}
	};

	handleDateChange(fieldName) {
        return (date) => {
            this.setState({
                searchStats: {
                    ...this.state.searchStats,
                    [fieldName]: date
                }
            });
        };
    }



	/**
	* For export PTO listing
	*/
	exportTableContent = (fileType) => {

		const { pageStats,apiExport } = this.props;
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let apiReq 	=	{...apiExport, ...{file_type: fileType}};

		if(searchStats && Object.keys(searchStats).length){
			let tmpSearchObj = {};
			Object.keys(searchStats).forEach(key=>{
				if(searchStats[key]) {
					if(searchStats[key] instanceof Date) {
						tmpSearchObj[key] = searchStats[key].toISOString();
					}
					if(searchStats[key].value) {
						tmpSearchObj[key] = searchStats[key].value;
					}else if(pageStats.search && pageStats.search.length){
						pageStats.search.forEach(element => {
							if(element.selector === key && element.input_type === "date_range"){
								tmpSearchObj["start_"+key] 	=	searchStats[key][0] ? formatDate(searchStats[key][0],CALENDAR_DATE_TIME_FORMAT) :"";
								tmpSearchObj["end_"+key] 	= 	searchStats[key][1] ? formatDate(searchStats[key][1],CALENDAR_DATE_TIME_FORMAT) :"";
							}
							if(element.isMultiple && searchStats[key].length && (element.input_type === "select" || element.input_type === "asyncPaginate")){
								searchStats[key].forEach(subEle =>{
									if(subEle.value){
										if(!tmpSearchObj[key]) tmpSearchObj[key] = [];
										tmpSearchObj[key].push(subEle.value);
									}
								});
							}
						});
					}
				}
			});

			apiReq = { ...apiReq, ...tmpSearchObj };
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					const newTab = window.open(response.data[0].file_url, '_blank');
					if (newTab) newTab.focus();
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	render(){
		const { pageStats, getDropDownList,apiExport} =	this.props;
		const { list, currentPage, totalPages, completeApiRes,isLoading, actionIndex, sortStats, rowsPerPage, searchStats, defaultDateRange} =	this.state;
		return (
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center section-head">
					<div className="left-heading">
						<h1>{pageStats.title} </h1>
					</div>
					<div className="right-button">
						{pageStats.search.length ?
							<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#searchCollapseOne" aria-expanded="true" aria-controls="searchCollapseOne">
								<img src={filterSvg} alt="Img" width={29} height={26} />
							</Link>
						:null}

						{pageStats.headerLinks && pageStats.headerLinks.length ?
							pageStats.headerLinks.map((linkData, linkIndex)=>
								<Link
									to={linkData.link ? linkData.link :"#"}
									className={"btn btn-fill "+(pageStats.headerLinks.length >1 && linkIndex ? 'ms-1' :'')}
									key={"header-link-"+linkIndex}
									onClick={()=>{ linkData.clickEvent(linkData.selector) }}
								>
									{linkData.name}
								</Link>
							)
						:null}
					</div>
				</div>
				{pageStats.search.length ?
					<div className="filter-box adduser-form">
						<div className="accordion" id="accordionTableSearch">
							<div className="accordion-item">
								<div id="searchCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionTableSearch">
									<div className="accordion-body">
										<div className="filter-box-header d-flex justify-content-between mb-3">
											<h3 className="mb-0">{translate("datatable.filter")}</h3>
											<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#searchCollapseOne" aria-expanded="true" aria-controls="searchCollapseOne">
												<img src={closeIcon} alt="Img" width={20} height={20} />
											</Link>
										</div>
										<div className="row">
											{pageStats.search.map((records, key) =>
												<div className={pageStats.search.length <= 2 ? "col-6" :"col-md-4"} key={"search-"+key}>

													<div className="form-group">
														{
															records.input_type === "text" ?
																<div className={"mb-3 "+(!records.showLabel ? "form-floating" :"")}>
																	{records.showLabel ?
																		<label className='mb-1 form-label' htmlFor={records.selector+key}>{records.name}</label>
																	:null}
																	<input type="text" className={"form-control "+(records.inputClass ? records.inputClass :"" )} id={records.selector+key} placeholder={records.name} onChange={(event) => this.handleFormInputs(event, records.selector)} value={ searchStats[records.selector] ? searchStats[records.selector].value : ""} />
																	{!records.showLabel ?
																		<label htmlFor={records.selector+key}>{records.name}</label>
																	:null}
																</div>
															:
															records.input_type === "select" ?
																<div className={records.showLabel ? "mb-3" : "form-floating mb-3"}>

																	{records.showLabel ?
																	<label className='mb-1 form-label' htmlFor={records.selector+key}>	{records.name}</label>
																	:null}

																	<Select
																		className="custom-react-select"
																		options={records.options ? records.options :[]}
																		closeMenuOnSelect={true}
																		placeholder={records.name}
																		value={searchStats[records.selector] ? searchStats[records.selector] :null}
																		isClearable={true}
																		onChange={this.searchSelectChange(records.selector)}
																	/>
																</div>
															: records.input_type === "date_range" ?
																<div className="form-group">
																	<label className='mb-1 form-label' htmlFor={records.selector+key}>{records.name}</label>
																	<div className="col-12">
																		<DateRangePicker
																			value={searchStats[records.selector] ? searchStats[records.selector] : null}
																			format={DATE_RANGE_PICKER_FORMAT}
																			size="lg"
																			editable={false}
																			className={"form-control "+(records.inputClass ? records.inputClass :"" )}
																			onChange={this.searchSelectChange(records.selector)}
																			placeholder={records.name}
																			onClean={()=>{
																				if(records.showDefaultRange) this.setState({searchStats: {...searchStats,...{[records.selector]: defaultDateRange}}})
																				else this.setState({searchStats: {...searchStats,...{[records.selector]: null }}})
																			}}
																		/>
																	</div>
																</div>
															: records.input_type === "date" ?
																<div className="form-floating mb-3">
																	<DatePicker
																		selected={searchStats[records.selector] ? new Date(searchStats[records.selector]) : null}
																		onChange={this.handleDateChange(records.selector)}
																		className="form-control"
																		id={records.selector + key}
																		dateFormat={DATE_PICKER_DATE_FORMAT}
																		placeholderText={records.name}
																	/>
																</div>
															: records.input_type === "asyncPaginate" && getDropDownList ?
																<div className="form-group mb-3">
																	<label className='mb-1 form-label' htmlFor={records.selector + key}>{records.name}</label>
																	<AsyncPaginate
																		className="custom-react-select"
																		inputId={records.selector + key}
																		key={records.parent_selector ? (
																				searchStats[records.parent_selector] && searchStats[records.parent_selector].value ? searchStats[records.parent_selector].value : records.parent_selector
																			) : records.selector}
																		value={searchStats[records.selector] ? searchStats[records.selector] : null}
																		loadOptions={getDropDownList}
																		isMulti={records.isMultiple ? records.isMultiple :false}
																		onChange={this.searchSelectChange(records.selector)}
																		additional={{ page: 1, type: records.selector, searchStats: searchStats }}
																		isClearable={true}
																		placeholder={records.name}
																	/>
																</div>
															: null
														}
													</div>
												</div>
											)}
											<div className="col-md-12 d-flex">
												<button className="btn btn-fill  me-3 width-height-initial" onClick={()=>{ this.handleChangePage(1) }}>
													{translate("system.submit")}
												</button>
												<button className="btn btn-outline" onClick={()=>{ this.resetSearchStats() }}>
													{translate("system.reset")}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				:null}

				{pageStats.addButton ? (
					<Link
						to={pageStats.addButton.link}
						className={pageStats.addButton.className}
						onClick={pageStats.addButton.onClick}
					>
						{pageStats.addButton.label}
					</Link>
				) : null}

				<div className="users-list bg-transparent p-0">
					<div className="row">
						<div className="col-md-6">
							<div className="form-group d-flex align-items-center mb-3 table-count">
								{translate("system.show")}
								<Select
									className="custom-react-select mx-1"
									options={PAGING_LENGTH_MENU.map(key=>{return {value: key, label: key }})}
									value={{label:rowsPerPage, value:rowsPerPage}}
									closeMenuOnSelect={true}
									onChange={this.handlePageLengthChange}
								/>
								{translate("system.entries")}
							</div>
						</div>
						{
							apiExport && Object.keys(apiExport).length ? (
								<div className="col-lg-6 text-end">
									<div className="form-group export-btns">
										<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
										<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
									</div>
								</div>
							) : null
						}
					</div>
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									{pageStats.columns.map((colData, colIndex)=>
										<th key={"head-column-"+colIndex} width={colData.width} className={colData.sortable ? 'cursor-pointer' :''}>
											<span
												className='headingText'
												onClick={(event)=>{this.manageSortingEvent(event, colData.sortable, colData.selector, (sortStats && sortStats.key === colData.selector && sortStats.dir === "asc" ? "desc" :"asc")) }}
											>
												{colData.name}
												{colData.selector && colData.sortable  ?
													<span className='dataIcon'>
														{
															((!sortStats || !sortStats.key) || (sortStats && sortStats.key !== colData.selector)) ?
																<img src={dataTable} alt="sorting-opt" />
															:(sortStats && sortStats.key === colData.selector ? (
																sortStats.dir === 'asc' ?
																	<img src={dataAsc} alt="sorting-asc"  />
																:
																<img src={dataDesc} alt="sorting-desc" />
															):null)
														}
													</span>
												:null}
											</span>
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{!isLoading && list && list.length  ?
									list.map((row, rowIndex)=>
										<tr key={"row"+rowIndex}>
											{pageStats.columns.map((colData, colIndex)=>
												colData.selector ?
													<td key={"row-data-"+rowIndex+"-"+colIndex}>
														{
															colData.customRender ? colData.customRender(colData.selector, row,completeApiRes):
															colData.is_date && row[colData.selector] ? formatDate(row[colData.selector],REPORT_DATE_FORMAT) : (row[colData.selector] ? row[colData.selector] :"-")
														}
													</td>
												:
												(colData.custom_action && colData.actions && colData.actions.length ?
													<td  key={"row-data-"+rowIndex+"-"+colIndex}>
														{colData.actions.length > 1 ?
															<div className="multi-action">
																<button
																	className={"action-button "+(actionIndex === rowIndex+1 ? "active" :"")}
																	onClick={()=>{ this.setState({
																		actionIndex: actionIndex !== rowIndex+1 ? rowIndex+1 : 0
																	}) }}
																>
																	<img src={moreSvg} alt="More" height={24} width={5} />
																</button>
																<div className="actions" onClick={()=>{this.setState({actionIndex: 0})}}>
																	{colData.actions.map((actData, actIndex)=>
																		<Link to="#" key={"row-action-" + +rowIndex +"-"+ colIndex +"-"+ actIndex} onClick={() => colData.custom_action(actData.key, row)} className={"btn btn-sm action-tags "+( actData.key === "delete" ? 'bg-red ' :"bg-teal" )}>
																			{
																				actData.key === "edit" ?
																					<img src={editIconSvg}alt="img" width={14} height={14} />
																				:
																				actData.key === "delete" ?
																					<img src={deleteIconSvg} alt="img" width={14} height={14} />
																				:null
																			}
																		</Link>
																	)}
																</div>
															</div>
														:
															<>
																<div className="moreaction">
																	{colData.actions[0].key === "edit" ?
																		<Link to="#" onClick={() => colData.custom_action(colData.actions[0].key, row)} >
																			<img src="/images/edit-icon.svg" alt="img" width={21} height={21} />
																		</Link>
																	:null}
																	{colData.actions[0].key === "delete" ?
																		<Link to="#" onClick={() => colData.custom_action(colData.actions[0].key, row)} >
																			<img src="/images/delete-icon.svg" alt="img" width={21} height={21} />
																		</Link>
																	:null}
																	{colData.actions[0].key === "view_job" ?
																		<Link to="#" className="btn btn-sm btn-fill width-initial" onClick={() => colData.custom_action(colData.actions[0].key, row)} >
																			{translate("system.view_job")}
																		</Link>
																	:null}
																</div>
															</>
														}
													</td>
												:null)
											)}
										</tr>
									)
								:(!isLoading ?
									<tr>
										<td colSpan={pageStats.columns.length} className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								:
									<tr>
										<td colSpan={pageStats.columns.length} className='text-center'>
											<img src="/images/pagination_loader.gif" alt="loading-icon" />
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
					{!isLoading && list.length && totalPages >1 ?
						<div className='d-flex justify-content-center align-items-center'>
							<EllipsisPagination currentPage={currentPage} totalPages={totalPages} onChange={this.handleChangePage} />
						</div>
					:null}
				</div>
			</div>
		);
	}
}

export default withRouter(NormalDataTable);